import { PEER_MESSAGE_TYPE_Y_INIT, PEER_MESSAGE_TYPE_Y_DISSMISS, PUBSUB_FIELD_TYPE_PREFIX_YDOC } from "../constants";
import { decodeFieldMessage, encodeFieldMessage } from "../encoding";
import { getFieldKey } from "../keys";
import { ICollaborationState, IPeer } from "../types";

export const handlePeerYDocMessage = (
  state: ICollaborationState,
  peer: IPeer,
  data: Uint8Array,
  peerMessageType: number,
) => {
  const field = decodeFieldMessage(data);
  const fieldKey = getFieldKey({
    type: PUBSUB_FIELD_TYPE_PREFIX_YDOC,
    blueprintFieldId: field.blueprintFieldId,
    documentId: field.documentId,
    documentFieldLocaleId: field.documentFieldLocaleId,
  });

  if (peerMessageType == PEER_MESSAGE_TYPE_Y_INIT && !state.pubsub._observers.has(fieldKey)) {
    // if we have no provider on this field, we should retun a dissmiss message to the peer
    peer.send(
      encodeFieldMessage({
        messageType: PEER_MESSAGE_TYPE_Y_DISSMISS,
        ...field,
      }),
    );
    return;
  }

  state.pubsub.emit(fieldKey, [{ ...field, peerMessageType, peer }]);
};
