import { decodeFieldMessage } from "../encoding";
import { ICollaborationState, IPeer } from "../types";
import { findOwnListeningField } from "./helper";

export const handleFieldDissmiss = (state: ICollaborationState, peer: IPeer, data: Uint8Array) => {
  const field = decodeFieldMessage(data);
  const ownListeningField = findOwnListeningField(state, field);
  if (!ownListeningField) {
    return;
  }

  ownListeningField.peersDismissed[peer.clientId] = true;
};
