import { cloneDeepWith, isObject } from "lodash";
import { getManyBlueprints } from "../../graphql/queries/getManyBlueprints.gql";
import { client } from "../../utils/client";
import { IAddField, IChange, IUseBlueprint } from "./types";
export type { IUseBlueprint } from "./types";
import { deleteBlueprint } from "../../graphql/mutations/deleteBlueprint.gql";
import {
  IBlueprintField,
  IBlueprintGroup,
  IBlueprintResponse,
  IBlueprintVariant,
  ICreateBlueprintRequest,
  ICreateBlueprintResponse,
  IDeleteBlueprintRequest,
  IDeleteBlueprintResponse,
  IGetBlueprintByIdResponse,
  IUpdateBlueprintRequest,
  IUpdateBlueprintResponse,
} from "../../interfaces/generated";
import { updateBlueprint as updateBlueprintGQL } from "../../graphql/mutations/updateBlueprint.gql";
import { createBlueprint as createBlueprintGQL } from "../../graphql/mutations/createBlueprint.gql";
import { getBlueprintById } from "../../graphql/queries/getBlueprintById.gql";
import { getBlueprintByName } from "../../graphql/queries/getBlueprintByName";
import { set as lodashSet } from "lodash";
import produce from "immer";
import { getDocumentCountsByBlueprints } from "../../graphql/queries/getDocumentCountsByBlueprints.gql";
import { message } from "@caisy/league";
import { I18n } from "../../provider/i18n";
import isEqual from "lodash/isEqual";

const DEFAULT_GROUP_WITH_TYPENAME: IBlueprintGroup = {
  name: "Main",
  blueprintGroupId: "Tmp-blueprint-group-id-main",
  fields: [],
  __typename: "BlueprintGroup",
};

let i = 0;
const createId = () => {
  return (i++).toString();
};

const omitSchema = (collection) => {
  const clonedCollection = JSON.parse(JSON.stringify(collection));

  // this will remove empty fields - we need this cleanup for the hasChanges check
  if (Array.isArray(clonedCollection)) {
    clonedCollection.forEach((_, i) => {
      clonedCollection[i].fields = clonedCollection[i].fields?.filter((f) => f && f.name && f.name !== "");
    });
  }

  return cloneDeepWith(
    clonedCollection,
    (value: {
      __typename: string;
      blueprintId: string;
      setIsDisplay: any;
      update: any;
      system: any;
      blueprintGroupId: string;
      blueprintFieldId: string;
      identifier: string;
    }) => {
      if (isObject(value)) {
        if (value.__typename) {
          delete value.__typename;
        }
        if (value.blueprintId) {
          delete value.blueprintId;
        }
        if (value.setIsDisplay) {
          delete value.setIsDisplay;
        }
        if (typeof value.system === "boolean") {
          delete value.system;
        }
        if (value.update) {
          delete value.update;
        }

        if (value.blueprintFieldId && value.blueprintFieldId.includes("Tmp-blueprint-field-id")) {
          delete value.blueprintFieldId;
        }

        if (value.blueprintGroupId && value.blueprintGroupId.includes("Tmp-blueprint-group-id")) {
          delete value.blueprintGroupId;
        }

        if (value.identifier) {
          delete value.identifier;
        }
      }
    },
  );
};

export interface IManyBlueprintState {
  blueprint: IUseBlueprint;
}

export const createBlueprintSlice = (
  set: (cb: (state: IManyBlueprintState) => IManyBlueprintState, replace: boolean, name: string) => void,
  get: () => IManyBlueprintState,
): IManyBlueprintState => ({
  blueprint: {
    blueprints: {} as { [key: string]: IBlueprintResponse },
    currentBlueprintId: "",
    currentSavedGroups: [],
    hasChanges: false,
    currentSearch: "",
    loadingBlueprints: false,
    blueprintsLoaded: false,
    blueprintsError: false,
    dataHash: undefined,
    isNextPageLoading: false,
    // hasNextPage: true,
    currentGroupId: null,
    lastBlueprintId: undefined,
    currentStateIndex: 0,
    changesStack: [],
    canUndo: false,
    canRedo: false,
    resetBlueprint: () => {
      set(
        produce<IManyBlueprintState>((state) => {
          state.blueprint.currentBlueprintId = "";
          state.blueprint.loadingBlueprints = false;
          state.blueprint.currentGroupId = null;
          state.blueprint.blueprintsLoaded = false;
          state.blueprint.blueprintsError = false;
          state.blueprint.isNextPageLoading = false;
          // state.blueprint.hasNextPage = true;
          state.blueprint.lastBlueprintId = undefined;
          state.blueprint.blueprints = {};
          state.blueprint.dataHash = undefined;
          state.blueprint.currentStateIndex = 0;
          state.blueprint.changesStack = [];
          state.blueprint.canUndo = false;
          state.blueprint.canRedo = false;
          state.blueprint.currentSavedGroups = [];
          state.blueprint.hasChanges = false;
        }),
        true,
        "blueprint/reset",
      );
    },
    loadBlueprintByName: async ({ name, projectId }: { name: string; projectId: string }) => {
      const entry = Object.values(get().blueprint.blueprints).find(
        (n: IBlueprintResponse) => n?.name && n?.name?.toLocaleLowerCase() === name?.toLocaleLowerCase(),
      );
      if (entry) {
        return entry;
      }

      const { data } = await client.query({
        query: getBlueprintByName,
        variables: { input: { blueprintName: name, projectId } },
        fetchPolicy: "no-cache",
      });

      if (data.GetBlueprintByName.blueprint) {
        const blueprint = data.GetBlueprintByName.blueprint as IBlueprintResponse;

        set(
          produce<IManyBlueprintState>((state) => {
            state.blueprint.blueprints[blueprint.blueprintId] = blueprint;
          }),
          false,
          "blueprint/loadBlueprintByName",
        );

        get().blueprint.loadBlueprintById({ blueprintId: blueprint.blueprintId, projectId });
        return blueprint;
      }
    },
    discardChanges: () => {
      const hasChanges = get().blueprint.hasChanges;
      const currentBlueprintId = get().blueprint.currentBlueprintId;
      const currentSavedGroups = get().blueprint.currentSavedGroups;

      if (currentBlueprintId && hasChanges) {
        set(
          produce<IManyBlueprintState>((state) => {
            state.blueprint.currentSavedGroups = [];
            state.blueprint.blueprints[currentBlueprintId].groups = currentSavedGroups;
            state.blueprint.hasChanges = false;
          }),
          false,
          "blueprint/discardChanges",
        );
      }
    },
    loadBlueprintById: async ({ blueprintId, projectId }: { projectId: string; blueprintId: string }) => {
      let blueprint = get().blueprint.blueprints[blueprintId];
      if (!blueprint) {
        const { data } = await client.query({
          query: getBlueprintById,
          variables: { input: { blueprintId, projectId } },
          fetchPolicy: "no-cache",
        });

        if (data.GetBlueprintById.blueprint) {
          blueprint = (data.GetBlueprintById as IGetBlueprintByIdResponse).blueprint;
        }
      }

      const blueprintGroupsAreEmpty = !blueprint.groups || blueprint.groups.length === 0;
      const blueprintWithDefaultGroup: IBlueprintResponse = { ...blueprint, groups: [DEFAULT_GROUP_WITH_TYPENAME] };

      set(
        produce<IManyBlueprintState>((state) => {
          state.blueprint.blueprints[blueprint.blueprintId] = blueprintGroupsAreEmpty
            ? blueprintWithDefaultGroup
            : blueprint;
          state.blueprint.currentBlueprintId = blueprint.blueprintId;
          state.blueprint.currentSavedGroups = omitSchema(blueprintGroupsAreEmpty ? [] : blueprint.groups.slice());
        }),
        false,
        "blueprint/loadBlueprintById",
      );

      get().blueprint.setCurrentGroup(blueprintGroupsAreEmpty ? DEFAULT_GROUP_WITH_TYPENAME : blueprint.groups[0]);

      set(
        produce<IManyBlueprintState>((state) => {
          state.blueprint.currentStateIndex = 0;
          state.blueprint.changesStack = [];
        }),
        false,
        "blueprint/loadBlueprintById/initializeChangesStack",
      );

      return blueprint;
    },

    getAllBlueprints: () => {
      return Object.values(get().blueprint.blueprints);
    },

    loadAllBlueprints: async ({ projectId }) => {
      try {
        const { data } = await client.query({
          query: getManyBlueprints,
          variables: {
            input: { projectId },
          },
          fetchPolicy: "no-cache",
        });

        if (data.GetManyBlueprints) {
          set(
            produce<IManyBlueprintState>((state) => {
              data.GetManyBlueprints.connection.edges.forEach((edge: any) => {
                state.blueprint.blueprints[edge.node.blueprintId] = edge.node;
              });
              state.blueprint.blueprintsLoaded = true;
            }),
            false,
            "blueprint/loadAllBlueprints",
          );
        }
      } catch (err) {
        console.log(err);
        set(
          produce<IManyBlueprintState>((state) => {
            state.blueprint.blueprintsError = true;
          }),
          false,
          "blueprint/loadAllBlueprints/error",
        );
      }

      set(
        produce<IManyBlueprintState>((state) => {
          state.blueprint.loadingBlueprints = false;
        }),
        false,
        "blueprint/loadAllBlueprints",
      );
    },

    // loadNextPage: async ({ projectId }) => {
    //   try {
    //     set(
    //       produce<IManyBlueprintState>((state) => {
    //         state.blueprint.isNextPageLoading = true;
    //       }),
    //       false,
    //       "blueprint/loadNextPage/loading",
    //     );
    //     const { data } = await client.query({
    //       query: getManyBlueprints,
    //       variables: {
    //         input: {
    //           projectId,
    //           paginationArguments: {
    //             first: 50,
    //             after: get().blueprint.lastBlueprintId,
    //           },
    //         },
    //       },
    //       fetchPolicy: "no-cache",
    //     });

    //     set(
    //       produce<IManyBlueprintState>((state) => {
    //         (data.GetManyBlueprints as IGetManyBlueprintsResponse).connection.edges.forEach((edge) => {
    //           state.blueprint.blueprints[edge.node.blueprintId] = edge.node;
    //         });
    //         state.blueprint.blueprintsLoaded = true;
    //         state.blueprint.hasNextPage = data.GetManyBlueprints.connection.pageInfo.hasNextPage;
    //         state.blueprint.isNextPageLoading = false;
    //         state.blueprint.lastBlueprintId = data.GetManyBlueprints.connection.pageInfo.endCursor;
    //       }),
    //       false,
    //       "blueprint/loadNextPage/done",
    //     );
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },

    deleteManyBlueprints: async ({ blueprintIds, projectId }) => {
      const blueprintsToDelete = get()
        .blueprint.getAllBlueprints()
        .filter(({ blueprintId }) => blueprintIds.includes(blueprintId));

      await Promise.all(
        blueprintsToDelete.map(({ blueprintId }) =>
          get().blueprint.deleteBlueprint({ input: { blueprintId, projectId } }),
        ),
      );
    },

    getCurrentBlueprint: () => {
      const blueprintId = get().blueprint.currentBlueprintId;
      if (!blueprintId) return;
      return get().blueprint.blueprints[get().blueprint.currentBlueprintId];
    },

    setCurrentGroup: (group) => {
      set(
        produce<IManyBlueprintState>((state) => {
          state.blueprint.currentGroupId = group?.blueprintGroupId;
        }),
        false,
        "blueprint/setCurrentGroup",
      );
    },

    getCurrentGroup: () => {
      const currentBlueprintId = get().blueprint?.currentBlueprintId;
      const currentGroupId = get().blueprint?.currentGroupId;

      return get().blueprint.blueprints?.[currentBlueprintId]?.groups.find(
        (group) => group.blueprintGroupId === currentGroupId,
      );
    },
    saveCurrentBlueprint: async ({ projectId }: { projectId: string }) => {
      const blueprint = get().blueprint.getCurrentBlueprint();

      const groups = omitSchema(blueprint.groups.slice());
      try {
        const { data } = await client.mutate({
          mutation: updateBlueprintGQL,
          variables: {
            input: {
              blueprintId: blueprint.blueprintId,
              projectId,
              input: {
                groups,
                description: blueprint.description,
                name: blueprint.name,
                previewImageUrl: blueprint.previewImageUrl,
                single: blueprint.single,
                title: blueprint.title,
                variant: blueprint.variant,
                exposeMutations: blueprint.exposeMutations,
                tagIds: blueprint.tagIds,
              },
            },
          },
        });

        const savedBlueprint: IBlueprintResponse = data.UpdateBlueprint.blueprint;

        set(
          produce<IManyBlueprintState>((state) => {
            state.blueprint.currentSavedGroups = omitSchema(savedBlueprint.groups.slice());
            state.blueprint.hasChanges = false;
            state.blueprint.blueprints[savedBlueprint.blueprintId] = savedBlueprint;
          }),
          false,
          "blueprint/saveCurrentBlueprint",
        );

        message.success(
          <I18n selector="blueprintDetailCommon.common_blueprintUpdated" fallback="Blueprint updated" />,
          {
            duration: 1000,
          },
        );
      } catch (err) {
        if (err.toString().includes("ResourceExhausted")) {
          message.error(
            <I18n
              selector="blueprintErrorMessages.blueprintFieldQuota"
              fallback="Quota breached: Your blueprint has more fields then the allowed limit. Please delete some fields and try again."
            />,
            {
              duration: 5000,
            },
          );

          return;
        }
        console.log(err);
      }
    },

    duplicateCurrentBlueprint: async ({ projectId }) => {
      const blueprint = get().blueprint.getCurrentBlueprint();

      const clonedBlueprint = await get().blueprint.duplicateBlueprintById({
        projectId,
        blueprintId: blueprint.blueprintId,
      });

      set(
        produce<IManyBlueprintState>((state) => {
          state.blueprint.currentSavedGroups = omitSchema(clonedBlueprint.groups.slice());
          state.blueprint.hasChanges = false;
          state.blueprint.currentBlueprintId = clonedBlueprint.blueprintId;
        }),
        false,
        "blueprint/duplicateCurrentBlueprint",
      );

      return clonedBlueprint;
    },

    duplicateBlueprintById: async ({ projectId, blueprintId }) => {
      const blueprint = get().blueprint.blueprints[blueprintId];

      const groups = omitSchema(blueprint.groups.slice()).map((group) => {
        delete group.blueprintGroupId;

        group.fields.forEach((field) => {
          delete field.blueprintGroupId;
          delete field.blueprintFieldId;
        });
        return group;
      });

      let clonedName = `Cloned${blueprint.name}`;
      let clonedTitle = `Cloned ${blueprint.title}`;

      if (
        get()
          .blueprint.getAllBlueprints()
          .find((blueprint) => blueprint.name === clonedName)
      ) {
        let clonedIndex = 0;
        clonedName = `Cloned0${blueprint.name}`;
        clonedTitle = `Cloned 0 ${blueprint.title}`;

        while (
          get()
            .blueprint.getAllBlueprints()
            .find((blueprint) => blueprint.name === clonedName)
        ) {
          clonedIndex++;
          clonedName = `Cloned${clonedIndex}${blueprint.name}`;
          clonedTitle = `Cloned ${clonedIndex} ${blueprint.title}`;
        }
      }

      try {
        const { data } = await client.mutate({
          mutation: createBlueprintGQL,
          variables: {
            input: {
              projectId,
              input: {
                groups,
                description: blueprint.description,
                name: clonedName,
                previewImageUrl: blueprint.previewImageUrl,
                single: blueprint.single,
                title: clonedTitle,
                variant: blueprint.variant,
                exposeMutations: blueprint.exposeMutations,
                tagIds: blueprint.tagIds,
              },
            },
          },
        });

        const clonedBlueprint: IBlueprintResponse = data.CreateBlueprint.blueprint;

        set(
          produce<IManyBlueprintState>((state) => {
            state.blueprint.blueprints[clonedBlueprint.blueprintId] = clonedBlueprint;
          }),
          false,
          "blueprint/duplicateBlueprintById",
        );
        return clonedBlueprint;
      } catch (error) {
        if (error.toString().includes("ResourceExhausted")) {
          message.error(
            (blueprint?.variant === IBlueprintVariant.BlueprintVariantComponent
              ? globalThis["i18n"]?.["blueprintErrorMessages"]?.["componentBlueprintQuota"]
              : globalThis["i18n"]?.["blueprintErrorMessages"]?.["documentBlueprintQuota"]) ||
              "Quota breached: You have reached the maximum number of blueprints",
            {
              duration: 5000,
            },
          );
          return;
        }
        console.error(error);
      }
    },

    duplicateManyBlueprints: async ({ projectId, blueprintIds }) => {
      const promises = blueprintIds.map((blueprintId) =>
        get().blueprint.duplicateBlueprintById({ blueprintId, projectId }),
      );

      return await Promise.all(promises);
    },

    deleteCurrentBlueprint: async ({ projectId }: { projectId: string }) => {
      const currentBlueprintId = get().blueprint.currentBlueprintId;
      get().blueprint.deleteBlueprint({ input: { blueprintId: currentBlueprintId, projectId } });
    },

    createBlueprint: async ({ input }: { input: ICreateBlueprintRequest }) => {
      try {
        const { data } = await client.mutate({
          mutation: createBlueprintGQL,
          variables: {
            input: {
              projectId: input.projectId,
              input: {
                ...input.input,
                groups: [
                  {
                    name: "Main",
                    fields: [],
                  },
                ],
              },
            },
          },
        });

        const blueprint = (data.CreateBlueprint as ICreateBlueprintResponse).blueprint;

        set(
          produce<IManyBlueprintState>((state) => {
            state.blueprint.blueprints[blueprint.blueprintId] = blueprint;
          }),
          false,
          "blueprint/createBlueprint",
        );

        return blueprint;
      } catch (error) {
        if (error.toString().includes("ResourceExhausted")) {
          message.error(
            (input?.input?.variant === IBlueprintVariant.BlueprintVariantComponent
              ? globalThis["i18n"]?.["blueprintErrorMessages"]?.["componentBlueprintQuota"]
              : globalThis["i18n"]?.["blueprintErrorMessages"]?.["documentBlueprintQuota"]) ||
              "Quota breached: You have reached the maximum number of blueprints",
            {
              duration: 5000,
            },
          );
          return;
        }
        console.error(error);
      }
    },

    updateBlueprint: async ({ input }: { input: IUpdateBlueprintRequest }) => {
      try {
        const currentBlueprint = get().blueprint.blueprints[input.blueprintId];
        const groups = omitSchema(currentBlueprint.groups.slice());

        const { data } = await client.mutate({
          mutation: updateBlueprintGQL,
          variables: {
            input: {
              ...input,
              input: {
                ...input.input,
                previewImageUrl: input.input.previewImageUrl || currentBlueprint.previewImageUrl,
                title: input.input.title || currentBlueprint.title,
                name: input.input.name || currentBlueprint.name,
                groups,
              },
            },
          },
        });

        const blueprint = (data.UpdateBlueprint as IUpdateBlueprintResponse).blueprint;

        set(
          produce<IManyBlueprintState>((state) => {
            state.blueprint.blueprints[blueprint.blueprintId] = blueprint;
          }),
          false,
          "blueprint/updateBlueprint",
        );

        return currentBlueprint;
      } catch (err) {
        if (err.toString().includes("ResourceExhausted")) {
          message.error(
            globalThis["i18n"]?.["blueprintErrorMessages"]?.["blueprintFieldQuota"] ||
              "Quota breached: Your blueprint has more fields then the allowed limit. Please delete some fields and try again.",
            {
              duration: 5000,
            },
          );
          return;
        }
        console.error(err);
      }
    },

    deleteBlueprint: async ({ input }: { input: IDeleteBlueprintRequest }) => {
      try {
        const { data } = await client.mutate({
          mutation: deleteBlueprint,
          variables: {
            input,
          },
        });

        const deleted = (data.DeleteBlueprint as IDeleteBlueprintResponse).deleted;

        if (deleted) {
          set(
            produce<IManyBlueprintState>((state) => {
              delete state.blueprint.blueprints[input.blueprintId];
            }),
            false,
            "blueprint/deleteBlueprint",
          );
        }

        return deleted;
      } catch (error) {}
    },

    createGroupOnCurrentBlueprint: (groupName: string) => {
      const currentBlueprint = get().blueprint.getCurrentBlueprint();
      const group: IBlueprintGroup = {
        __typename: "BlueprintGroup",
        name: groupName,
        blueprintGroupId: `Tmp-blueprint-group-id-${createId()}`,
        fields: [],
      };

      const newValue = currentBlueprint.groups.slice();
      newValue.push(group);
      const changes: IChange[] = [
        {
          newValue,
          oldValue: currentBlueprint.groups,
          fieldChanged: `groups`,
        },
      ];

      get().blueprint.increaseChangesStack(changes, "createGroupOnCurrentBlueprint");

      return group;
    },

    updateGroupOnCurrentBlueprint: (blueprintGroupId: string, newGroupName: string) => {
      const currentBlueprint = get().blueprint.getCurrentBlueprint();
      const groupIdx = currentBlueprint.groups.findIndex((group) => group.blueprintGroupId === blueprintGroupId);

      if (groupIdx === -1) return;

      const newValue = newGroupName;
      const oldValue = currentBlueprint.groups[groupIdx].name;
      const fieldChanged = `groups[${groupIdx}].name`;

      const changes = [
        {
          newValue,
          oldValue,
          fieldChanged,
        },
      ];

      get().blueprint.increaseChangesStack(changes, "updateGroupOnCurrentBlueprint");
    },

    deleteGroupFromCurrentBlueprint: (blueprintGroupId: string) => {
      const currentBlueprint = get().blueprint.getCurrentBlueprint();
      const groups = currentBlueprint.groups;

      const newValue = groups.filter((group) => group.blueprintGroupId !== blueprintGroupId);
      const oldValue = groups.slice();
      const fieldChanged = `groups`;

      const changes = [
        {
          newValue,
          oldValue,
          fieldChanged,
        },
      ];

      get().blueprint.increaseChangesStack(changes, "deleteGroupFromCurrentBlueprint");
    },

    createFieldOnCurrentGroup: (field: IAddField, index: number) => {
      const currentGroupId = get().blueprint.currentGroupId;
      const currentGroup = get().blueprint.getCurrentGroup();
      const currentGroupIndex = get()
        .blueprint.getCurrentBlueprint()
        .groups.findIndex((group) => group.blueprintGroupId === currentGroupId);

      const fieldToAdd: IBlueprintField = {
        ...field,
        __typename: "BlueprintField",
        blueprintFieldId: `Tmp-blueprint-field-id-${createId()}`,
      };

      const fields = currentGroup.fields ? currentGroup.fields.slice() : [];

      const oldValue = fields.slice();
      const newValue = fields.slice();
      newValue.splice(index, 0, fieldToAdd);
      const fieldChanged = `groups[${currentGroupIndex}].fields`;

      const changes = [
        {
          oldValue,
          newValue,
          fieldChanged,
        },
      ];

      get().blueprint.increaseChangesStack(changes, "createFieldOnCurrentGroup");

      return fieldToAdd;
    },

    updateFieldOnCurrentGroup: (field: IBlueprintField) => {
      const currentGroup = get().blueprint.getCurrentGroup();
      const currentGroupIndex = get()
        .blueprint.getCurrentBlueprint()
        .groups.findIndex((group) => group.blueprintGroupId === currentGroup.blueprintGroupId);
      const fieldIndex = currentGroup.fields.findIndex((f) => f.blueprintFieldId === field.blueprintFieldId);

      const fields = currentGroup.fields ? currentGroup.fields.slice() : [];
      const oldValue = fields[fieldIndex];
      const newValue = field;
      const fieldChanged = `groups[${currentGroupIndex}].fields[${fieldIndex}]`;

      const changes = [
        {
          oldValue,
          newValue,
          fieldChanged,
        },
      ];

      get().blueprint.increaseChangesStack(changes, "updateFieldOnCurrentGroup");
    },

    moveFieldOnCurrentGroup: (blueprintFieldId, destinationIndex) => {
      const currentBlueprint = get().blueprint.getCurrentBlueprint();
      const currentGroup = get().blueprint.getCurrentGroup();
      const groupIndex = currentBlueprint.groups.findIndex(
        (group) => group.blueprintGroupId === currentGroup.blueprintGroupId,
      );
      const fieldToMove = currentGroup.fields.find((field) => field.blueprintFieldId === blueprintFieldId);

      const newFields = currentGroup.fields.filter((field) => field.blueprintFieldId !== blueprintFieldId);

      newFields.splice(destinationIndex, 0, fieldToMove);

      const oldValue = currentGroup.fields ? currentGroup.fields.slice() : [];
      const newValue = oldValue.slice().filter((field) => field.blueprintFieldId !== blueprintFieldId);
      newValue.splice(destinationIndex, 0, fieldToMove);
      const fieldChanged = `groups[${groupIndex}].fields`;

      const changes = [
        {
          oldValue,
          newValue,
          fieldChanged,
        },
      ];

      get().blueprint.increaseChangesStack(changes, "moveFieldOnCurrentGroup");
    },

    deleteFieldFromCurrentGroup: (blueprintFieldId: string) => {
      const currentGroupId = get().blueprint.currentGroupId;
      const currentGroup = get().blueprint.getCurrentGroup();
      const currentGroupIndex = get()
        .blueprint.getCurrentBlueprint()
        .groups.findIndex((group) => group.blueprintGroupId === currentGroupId);

      const oldValue = currentGroup.fields ? currentGroup.fields.slice() : [];
      const newValue = oldValue.slice().filter((field) => field.blueprintFieldId !== blueprintFieldId);
      const fieldChanged = `groups[${currentGroupIndex}].fields`;

      const changes = [
        {
          oldValue,
          newValue,
          fieldChanged,
        },
      ];

      get().blueprint.increaseChangesStack(changes, "deleteFieldFromCurrentGroup");
    },

    toggleSetFieldAsPrimary: (blueprintFieldId: string) => {
      const currentBlueprint = get().blueprint.getCurrentBlueprint();

      const field = get()
        .blueprint.getAllFields()
        .find((field) => field.blueprintFieldId === blueprintFieldId);

      const fieldGroupIndex = currentBlueprint.groups.findIndex((group) => group.fields.includes(field));

      const fieldIndex = currentBlueprint.groups[fieldGroupIndex].fields.findIndex(
        (field) => field.blueprintFieldId === blueprintFieldId,
      );

      const oldValue = field?.options?.primary;
      const newValue = !oldValue;
      const fieldChanged = `groups[${fieldGroupIndex}].fields[${fieldIndex}].options.primary`;

      const changes = [
        {
          oldValue,
          newValue,
          fieldChanged,
        },
      ];

      if (newValue) {
        const oldPrimaryField = get()
          .blueprint.getAllFields()
          .find((field) => field?.options?.primary === true);

        if (oldPrimaryField) {
          const oldPrimaryFieldGroupIndex = currentBlueprint.groups.findIndex((group) =>
            group.fields.includes(oldPrimaryField),
          );

          const oldPrimaryFieldIndex = currentBlueprint.groups[oldPrimaryFieldGroupIndex].fields.findIndex(
            (field) => field.blueprintFieldId === oldPrimaryField.blueprintFieldId,
          );

          changes.push({
            oldValue: true,
            newValue: false,
            fieldChanged: `groups[${oldPrimaryFieldGroupIndex}].fields[${oldPrimaryFieldIndex}].options.primary`,
          });
        }
      }

      get().blueprint.increaseChangesStack(changes, "toggleSetFieldAsPrimary");
    },

    getAllFields: () => {
      const fields = [];

      const currentBlueprint = get().blueprint.getCurrentBlueprint();

      if (!currentBlueprint) return fields;

      currentBlueprint.groups?.forEach((group) => {
        group?.fields?.forEach((field) => fields.push(field));
      });

      return fields;
    },

    updateCurrentBlueprintFromChanges: (changes, functionName) => {
      const currentBlueprintId = get().blueprint.currentBlueprintId;
      const currentSavedGroups = get().blueprint.currentSavedGroups;

      set(
        produce<IManyBlueprintState>((state) => {
          changes.forEach((change) => {
            lodashSet(state, `blueprint.blueprints[${currentBlueprintId}].${change.fieldChanged}`, change.newValue);
          });
          const upcomingGroups = omitSchema(state.blueprint.blueprints[currentBlueprintId].groups.slice());
          const hasChanges = !isEqual(currentSavedGroups, upcomingGroups);
          state.blueprint.hasChanges = hasChanges;
        }),
        false,
        `blueprint/updateStateFromChanges/${functionName}`,
      );
    },

    increaseChangesStack: (changes, functionName) => {
      if (get().blueprint.currentStateIndex !== get().blueprint.changesStack.length) {
        set(
          produce<IManyBlueprintState>((state) => {
            state.blueprint.changesStack = [];
            state.blueprint.currentStateIndex = 0;
          }),
          false,
          `blueprint/increaseChangesStack/initialize/${functionName}`,
        );
      }
      get().blueprint.updateCurrentBlueprintFromChanges(changes, functionName);
      set(
        produce<IManyBlueprintState>((state) => {
          state.blueprint.changesStack.push(changes);
          state.blueprint.currentStateIndex = get().blueprint.currentStateIndex + 1;
        }),
        false,
        `blueprint/increaseChangesStack/${functionName}`,
      );
    },

    undoChangesFromCurrentBlueprint: () => {
      const currentBlueprintId = get().blueprint.currentBlueprintId;
      if (get().blueprint.currentStateIndex > 0) {
        const idx = get().blueprint.currentStateIndex - 1;
        const changes = get().blueprint.changesStack[idx];
        changes.forEach((change) => {
          set(
            produce((state) => {
              lodashSet(state, `blueprint.blueprints[${currentBlueprintId}].${change.fieldChanged}`, change.oldValue);
            }),
            false,
            "blueprint/indoChangesFromCurrentBlueprint",
          );
        });
        set(
          produce<IManyBlueprintState>((state) => {
            state.blueprint.canRedo = get().blueprint.currentStateIndex - 1 > 0;
            state.blueprint.currentStateIndex -= 1;
          }),
          false,
          "blueprint/undoChangesFromCurrentBlueprint/decreaseStateIndex",
        );
      }
    },

    redoChangesFromCurrentBlueprint: () => {
      const currentBlueprintId = get().blueprint.currentBlueprintId;
      if (get().blueprint.currentStateIndex < get().blueprint.changesStack.length) {
        const changes = get().blueprint.changesStack[get().blueprint.currentStateIndex];
        changes.forEach((change) => {
          set(
            produce<IManyBlueprintState>((state) => {
              lodashSet(state, `blueprint.blueprints[${currentBlueprintId}].${change.fieldChanged}`, change.newValue);
            }),
            false,
            "blueprint/redoChangesFromCurrentBlueprint",
          );
        });
        set(
          produce<IManyBlueprintState>((state) => {
            state.blueprint.canUndo = get().blueprint.currentStateIndex + 1 < get().blueprint.changesStack.length;
            state.blueprint.currentStateIndex += 1;
          }),
          false,
          "blueprint/redoChangesFromCurrentBlueprint/increaseStateIndex",
        );
      }
    },

    selectBlueprintById: (blueprintId: string) => get().blueprint.blueprints[blueprintId],

    getDocumentCountsByBlueprints: async ({ blueprintId, projectId }) => {
      const { data } = await client.query({
        query: getDocumentCountsByBlueprints,
        variables: { input: { blueprintId: blueprintId, projectId } },
      });

      const { counts } = data.GetDocumentCountsByBlueprints;
      return counts[0].count;
    },

    updateSearch: (newSearch) => {
      set(
        produce((state) => {
          state.blueprint.currentSearch = newSearch;
        }),
        false,
        "blueprint/updateSearch",
      );
    },

    moveGroupOnCurrentBlueprint: (blueprintGroupId: string, destinationIndex: number) => {
      const currentBlueprint = get().blueprint.getCurrentBlueprint();
      const groupToMove = currentBlueprint.groups.find((group) => group.blueprintGroupId === blueprintGroupId);

      const newGroups = currentBlueprint.groups.filter((group) => group.blueprintGroupId !== blueprintGroupId);

      newGroups.splice(destinationIndex, 0, groupToMove);

      const oldValue = currentBlueprint.groups ? currentBlueprint.groups.slice() : [];
      const newValue = oldValue.slice().filter((group) => group.blueprintGroupId !== blueprintGroupId);
      newValue.splice(destinationIndex, 0, groupToMove);
      const fieldChanged = `groups`;

      const changes = [
        {
          oldValue,
          newValue,
          fieldChanged,
        },
      ];

      get().blueprint.increaseChangesStack(changes, "moveGroupOnCurrentBlueprint");
    },

    moveFieldToGroup: (blueprintFieldId: string, destinationGroupId: string) => {
      const currentBlueprint = get().blueprint.getCurrentBlueprint();

      const sourceGroup = currentBlueprint.groups.find((group) =>
        group.fields?.some((field) => field.blueprintFieldId === blueprintFieldId),
      );

      if (sourceGroup.blueprintGroupId === destinationGroupId) return;

      const fieldToMove = currentBlueprint.groups
        .find((group) => group.blueprintGroupId === sourceGroup.blueprintGroupId)
        .fields.find((field) => field.blueprintFieldId === blueprintFieldId);

      const newSourceGroupFields = (sourceGroup?.fields || []).filter(
        (field) => field.blueprintFieldId !== blueprintFieldId,
      );

      const destinationGroup = currentBlueprint.groups.find((group) => group.blueprintGroupId === destinationGroupId);

      const newDestinationGroupFields =
        destinationGroup?.fields && Array.isArray(destinationGroup?.fields)
          ? [...destinationGroup.fields.slice(), fieldToMove]
          : [fieldToMove];

      const newGroups = currentBlueprint.groups.map((group) => {
        if (group.blueprintGroupId === destinationGroupId) {
          return {
            ...group,
            fields: newDestinationGroupFields,
          };
        }
        if (group.blueprintGroupId === sourceGroup.blueprintGroupId) {
          return {
            ...group,
            fields: newSourceGroupFields,
          };
        }
        return group;
      });

      const oldValue = currentBlueprint.groups ? currentBlueprint.groups.slice() : [];
      const newValue = newGroups;
      const fieldChanged = `groups`;

      const changes = [
        {
          oldValue,
          newValue,
          fieldChanged,
        },
      ];

      get().blueprint.increaseChangesStack(changes, "moveFieldToGroup");
    },
  },
});
