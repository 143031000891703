import produce from "immer";
import { getGroupQuotaPlan } from "../../graphql/queries/getGroupQuotaPlan.gql";
import { client } from "../../utils/client";
import { IUseGroupQuotaSlice } from "./types";

const defaultPlanValue = {
  maxMembers: 0,
  availableSystemRoles: 110101,
};

export const createGroupQuotaSlice = (
  set: (cb: (state: IUseGroupQuotaSlice) => IUseGroupQuotaSlice, replace: boolean, name: string) => void,
) => ({
  groupQuota: {
    planLoaded: false,
    plan: defaultPlanValue,
    // this will be ditched with Q124 release
    getGroupQuotaPlan: async (input: any) => {
      const { data } = await client.query({
        query: getGroupQuotaPlan,
        variables: { input },
      });

      if (!data.GetGroupQuotaPlan.quotaPlan) return;

      set(
        produce((state) => {
          state.groupQuota.plan = data.GetGroupQuotaPlan.quotaPlan;
          state.groupQuota.planLoaded = true;
        }),
        false,
        "groupQuota/getGroupQuotaPlan",
      );
    },
    reset: () => {
      set(
        produce<IUseGroupQuotaSlice>((state) => {
          state.groupQuota.plan = defaultPlanValue;
          state.groupQuota.planLoaded = false;
        }),
        false,
        "groupQuota/reset",
      );
    },
  },
});
