import { createGlobalStyle } from "styled-components";

const GSHideCookieYes = createGlobalStyle`
    .cky-overlay, body > .cky-overlay,
    .cky-consent-container, body > .cky-consent-container,
    .cky-modal, body > .cky-modal,
    .cky-btn-revisit-wrapper, body > .cky-btn-revisit-wrapper{
        display: none; 
    }
`;

export default GSHideCookieYes;
