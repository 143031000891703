import { PEER_MESSAGE_TYPE_FIELD_DISSMISS, PEER_MESSAGE_TYPE_FIELD_SUBSCRIBE_ON } from "../constants";
import { decodeFieldMessage, encodeFieldMessage } from "../encoding";
import { getFieldKey } from "../keys";
import { ICollaborationState, IPeer } from "../types";
import { findOwnListeningField } from "./helper";

export const handleFieldSubscribeOn = (state: ICollaborationState, peer: IPeer, data: Uint8Array) => {
  state.mutex.runExclusive(async () => {
    const field = decodeFieldMessage(data);
    const ownListeningField = findOwnListeningField(state, field);
    if (!ownListeningField) {
      peer.send(
        encodeFieldMessage({
          messageType: PEER_MESSAGE_TYPE_FIELD_DISSMISS,
          ...field,
        }),
      );
      return;
    }

    addFieldListener({ state, clientId: peer.clientId, ...field });
  });
};

const addFieldListener = ({
  state,
  documentFieldLocaleId,
  blueprintFieldId,
  documentId,
  clientId,
}: {
  state: ICollaborationState;
  documentFieldLocaleId?: string;
  blueprintFieldId: string;
  documentId: string;
  clientId: string;
}) => {
  const fieldKey = getFieldKey({
    type: PEER_MESSAGE_TYPE_FIELD_SUBSCRIBE_ON,
    blueprintFieldId,
    documentId,
    documentFieldLocaleId,
  });
  if (state.fieldListener[fieldKey]) {
    state.fieldListener[fieldKey] = [...state.fieldListener[fieldKey].filter((c) => c !== clientId), clientId];
  } else {
    state.fieldListener[fieldKey] = [clientId];
  }
  state.pubsub.emit(fieldKey, [state.fieldListener[fieldKey]]);
};
