import { useMemo, useEffect } from "react";
import { useProfile } from "../../stores/profile/useProfile";
import { onlyUnique } from "../../utils/onlyUnique";
import { useCollaboration } from "./CollaborationContext";
import { IUserProfile } from "../../interfaces/generated";
import { ICollaborationLocation } from "./types";

export interface ICollaborator extends IUserProfile {
  clientId: string;
  location?: ICollaborationLocation;
}

export const useCollaborator = ({ clientIds }: { clientIds: string[] }) => {
  const { useCollaboratorLocation } = useCollaboration();
  const clientLocations = useCollaboratorLocation();

  const { profiles, loadUserProfilesByIds } = useProfile();

  const activeProfiles = useMemo(() => {
    if (clientIds.length === 0 || Object.keys(profiles).length === 0) return [];

    const activeProfiles = [];
    clientIds.forEach((clientId) => {
      if (!clientId) return;

      const userId = clientId.includes("_") ? clientId.split("_")[1] : clientId;
      if (profiles[userId]) {
        activeProfiles.push({ ...profiles[userId], clientId });
      }
    });

    return activeProfiles;
  }, [clientIds?.length, Object.keys(clientIds).length, clientIds, profiles]);

  useEffect(() => {
    if (clientIds.length === 0) return;

    loadUserProfilesByIds({
      userIds: clientIds
        .filter((c) => !!c)
        .map((clientId) => (clientId.includes("_") ? clientId.split("_")[1] : clientId))
        .filter(onlyUnique),
    });
  }, [clientIds.length, clientIds]);

  return activeProfiles.map((profile) => {
    return {
      ...profile,
      location: clientLocations[profile.clientId],
    };
  });
};
