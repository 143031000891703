export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  BigInt: any;
  /** The `Byte` scalar type represents byte value as a Buffer */
  Byte: any;
  DateTime: any;
  Map: any;
  Object: any;
  Time: any;
  _Any: any;
  _FieldSet: any;
};

export type IApiKeyInitalResponse = {
  __typename: 'APIKeyInitalResponse';
  apiKeyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  scope?: Maybe<IApiKeyScope>;
  value?: Maybe<Scalars['String']>;
};

export type IApiKeyInput = {
  name?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<IApiKeyScopeInput>;
};

export type IApiKeyResponse = {
  __typename: 'APIKeyResponse';
  apiKeyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  scope?: Maybe<IApiKeyScope>;
};

export type IApiKeyScope = {
  __typename: 'APIKeyScope';
  readDraft?: Maybe<Scalars['Boolean']>;
  write?: Maybe<Scalars['Boolean']>;
};

export type IApiKeyScopeInput = {
  readDraft?: InputMaybe<Scalars['Boolean']>;
  write?: InputMaybe<Scalars['Boolean']>;
};

export type IAccessTokenResponse = {
  __typename: 'AccessTokenResponse';
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IActivateMembershipFromInvitationCodeRequestInput = {
  invitationCode?: InputMaybe<Scalars['String']>;
};

export type IActivateMembershipFromInvitationCodeResponse = {
  __typename: 'ActivateMembershipFromInvitationCodeResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type IActivateMembershipRequestInput = {
  organizationId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type IActivateMembershipResponse = {
  __typename: 'ActivateMembershipResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type IAggregatedMemberResponse = {
  __typename: 'AggregatedMemberResponse';
  memberStatus?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<IRole>>>;
  user?: Maybe<IUserProfile>;
};

export enum IAllNoneEnum {
  All = 'ALL',
  None = 'NONE'
}

export type IAmountItem = {
  __typename: 'AmountItem';
  operation?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type IAnyToRoleConnection = {
  __typename: 'AnyToRoleConnection';
  title?: Maybe<Scalars['String']>;
};

export type IAssignManyRolesRequestInput = {
  requests?: InputMaybe<Array<InputMaybe<IAssignRoleRequestInput>>>;
};

export type IAssignManyRolesResponse = {
  __typename: 'AssignManyRolesResponse';
  success?: Maybe<Scalars['Boolean']>;
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IAssignRoleRequestInput = {
  emailAddress?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['String']>;
  silent?: InputMaybe<Scalars['Boolean']>;
  systemRole?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IAssignRoleResponse = {
  __typename: 'AssignRoleResponse';
  memberId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
};

export type IAuditEventConnection = {
  __typename: 'AuditEventConnection';
  edges?: Maybe<Array<Maybe<IAuditEventConnectionEdge>>>;
  pageInfo?: Maybe<IPageInfo>;
  totalCount?: Maybe<Scalars['BigInt']>;
};

export type IAuditEventConnectionEdge = {
  __typename: 'AuditEventConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<IAuditEventPayload>;
};

export type IAuditEventMeta = {
  __typename: 'AuditEventMeta';
  blueprintFieldId?: Maybe<Scalars['String']>;
  blueprintId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Time']>;
  documentId?: Maybe<Scalars['String']>;
  documentLocaleId?: Maybe<Scalars['String']>;
  documentStatusId?: Maybe<Scalars['Int']>;
  effectedUserId?: Maybe<Scalars['String']>;
  sourceProjectId?: Maybe<Scalars['String']>;
  triggeredUserId?: Maybe<Scalars['String']>;
  userRole?: Maybe<Scalars['String']>;
};

export type IAuditEventPayload = {
  __typename: 'AuditEventPayload';
  body?: Maybe<Scalars['Byte']>;
  eventId?: Maybe<Scalars['String']>;
  metaData?: Maybe<IAuditEventMeta>;
  scope?: Maybe<IAuditEventScope>;
  trigger?: Maybe<IAuditEventTrigger>;
};

export type IAuditEventScope = {
  __typename: 'AuditEventScope';
  projectId?: Maybe<Scalars['String']>;
};

export enum IAuditEventTrigger {
  BlueprintCreate = 'BLUEPRINT_CREATE',
  BlueprintDelete = 'BLUEPRINT_DELETE',
  BlueprintUpdate = 'BLUEPRINT_UPDATE',
  DocumentCreate = 'DOCUMENT_CREATE',
  DocumentDelete = 'DOCUMENT_DELETE',
  DocumentFieldUpdate = 'DOCUMENT_FIELD_UPDATE',
  DocumentUpdate = 'DOCUMENT_UPDATE',
  ProjectCreate = 'PROJECT_CREATE',
  ProjectDelete = 'PROJECT_DELETE',
  ProjectImport = 'PROJECT_IMPORT',
  ProjectMemberAssinged = 'PROJECT_MEMBER_ASSINGED',
  ProjectMemberDeassinged = 'PROJECT_MEMBER_DEASSINGED',
  ProjectUpdate = 'PROJECT_UPDATE'
}

export type IAuthenticateParamsInput = {
  access_token?: InputMaybe<Scalars['String']>;
  access_token_secret?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<IUserInput>;
};

export type IBlueprintChangeSet = {
  __typename: 'BlueprintChangeSet';
  fields?: Maybe<Array<Maybe<IBlueprintFieldChangeSet>>>;
  sourceBlueprintId?: Maybe<Scalars['String']>;
  targetBlueprintId?: Maybe<Scalars['String']>;
};

export type IBlueprintField = {
  __typename: 'BlueprintField';
  blueprintFieldId?: Maybe<Scalars['String']>;
  blueprintGroupId?: Maybe<Scalars['String']>;
  blueprintId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<IBlueprintFieldOptions>;
  system?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<IBlueprintFieldType>;
};

export type IBlueprintFieldChangeSet = {
  __typename: 'BlueprintFieldChangeSet';
  sourceBlueprintFieldId?: Maybe<Scalars['String']>;
  targetBlueprintFieldId?: Maybe<Scalars['String']>;
};

export type IBlueprintFieldCode = {
  __typename: 'BlueprintFieldCode';
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type IBlueprintFieldCodeInput = {
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
};

export type IBlueprintFieldConnection = {
  __typename: 'BlueprintFieldConnection';
  connectedIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  multiple?: Maybe<Scalars['Boolean']>;
  variant?: Maybe<IBlueprintVariant>;
  visualization?: Maybe<IBlueprintFieldConnectionVisualization>;
};

export type IBlueprintFieldConnectionInput = {
  connectedIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
  multiple?: InputMaybe<Scalars['Boolean']>;
  variant?: InputMaybe<IBlueprintVariant>;
  visualization?: InputMaybe<IBlueprintFieldConnectionVisualization>;
};

export enum IBlueprintFieldConnectionVisualization {
  BlueprintFieldConnectionVisualizationDeafult = 'BLUEPRINT_FIELD_CONNECTION_VISUALIZATION_DEAFULT',
  BlueprintFieldConnectionVisualizationGrid = 'BLUEPRINT_FIELD_CONNECTION_VISUALIZATION_GRID',
  BlueprintFieldConnectionVisualizationInline = 'BLUEPRINT_FIELD_CONNECTION_VISUALIZATION_INLINE',
  BlueprintFieldConnectionVisualizationUnspecified = 'BLUEPRINT_FIELD_CONNECTION_VISUALIZATION_UNSPECIFIED'
}

export type IBlueprintFieldDatetime = {
  __typename: 'BlueprintFieldDatetime';
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  time?: Maybe<Scalars['Boolean']>;
};

export type IBlueprintFieldDatetimeInput = {
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
  time?: InputMaybe<Scalars['Boolean']>;
};

export type IBlueprintFieldExtension = {
  __typename: 'BlueprintFieldExtension';
  height?: Maybe<Scalars['Int']>;
  pattern?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type IBlueprintFieldExtensionInput = {
  height?: InputMaybe<Scalars['Int']>;
  pattern?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type IBlueprintFieldFile = {
  __typename: 'BlueprintFieldFile';
  focalPoint?: Maybe<Scalars['Boolean']>;
  pattern?: Maybe<Scalars['String']>;
};

export type IBlueprintFieldFileInput = {
  focalPoint?: InputMaybe<Scalars['Boolean']>;
  pattern?: InputMaybe<Scalars['String']>;
};

export type IBlueprintFieldFloat = {
  __typename: 'BlueprintFieldFloat';
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type IBlueprintFieldFloatInput = {
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
};

export type IBlueprintFieldInput = {
  blueprintFieldId?: InputMaybe<Scalars['String']>;
  blueprintGroupId?: InputMaybe<Scalars['String']>;
  blueprintId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<IBlueprintFieldOptionsInput>;
  system?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<IBlueprintFieldType>;
};

export type IBlueprintFieldInputInput = {
  blueprintFieldId?: InputMaybe<Scalars['String']>;
  blueprintGroupId?: InputMaybe<Scalars['String']>;
  blueprintId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<IBlueprintFieldOptionsInput>;
  system?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<IBlueprintFieldType>;
};

export type IBlueprintFieldInt = {
  __typename: 'BlueprintFieldInt';
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type IBlueprintFieldIntInput = {
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
};

export type IBlueprintFieldOptions = {
  __typename: 'BlueprintFieldOptions';
  code?: Maybe<IBlueprintFieldCode>;
  connection?: Maybe<IBlueprintFieldConnection>;
  datetime?: Maybe<IBlueprintFieldDatetime>;
  disableInApi?: Maybe<Scalars['Boolean']>;
  disableInUi?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<IBlueprintFieldExtension>;
  external?: Maybe<Scalars['String']>;
  file?: Maybe<IBlueprintFieldFile>;
  float?: Maybe<IBlueprintFieldFloat>;
  int?: Maybe<IBlueprintFieldInt>;
  localized?: Maybe<Scalars['Boolean']>;
  primary?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  richtext?: Maybe<IBlueprintFieldRichtext>;
  select?: Maybe<IBlueprintFieldSelect>;
  string?: Maybe<IBlueprintFieldString>;
  tag?: Maybe<IBlueprintFieldTag>;
  uniqueGlobal?: Maybe<Scalars['Boolean']>;
  uniqueLocal?: Maybe<Scalars['Boolean']>;
  video?: Maybe<IBlueprintFieldVideo>;
};

export type IBlueprintFieldOptionsInput = {
  code?: InputMaybe<IBlueprintFieldCodeInput>;
  connection?: InputMaybe<IBlueprintFieldConnectionInput>;
  datetime?: InputMaybe<IBlueprintFieldDatetimeInput>;
  disableInApi?: InputMaybe<Scalars['Boolean']>;
  disableInUi?: InputMaybe<Scalars['Boolean']>;
  extension?: InputMaybe<IBlueprintFieldExtensionInput>;
  external?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<IBlueprintFieldFileInput>;
  float?: InputMaybe<IBlueprintFieldFloatInput>;
  int?: InputMaybe<IBlueprintFieldIntInput>;
  localized?: InputMaybe<Scalars['Boolean']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  required?: InputMaybe<Scalars['Boolean']>;
  richtext?: InputMaybe<IBlueprintFieldRichtextInput>;
  select?: InputMaybe<IBlueprintFieldSelectInput>;
  string?: InputMaybe<IBlueprintFieldStringInput>;
  tag?: InputMaybe<IBlueprintFieldTagInput>;
  uniqueGlobal?: InputMaybe<Scalars['Boolean']>;
  uniqueLocal?: InputMaybe<Scalars['Boolean']>;
  video?: InputMaybe<IBlueprintFieldVideoInput>;
};

export type IBlueprintFieldRichtext = {
  __typename: 'BlueprintFieldRichtext';
  assetBlueprintIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  assetLinking?: Maybe<Scalars['Boolean']>;
  componentBlueprintIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  componentLinking?: Maybe<Scalars['Boolean']>;
  documentBlueprintIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  documentLinking?: Maybe<Scalars['Boolean']>;
  features?: Maybe<IBlueprintFieldRichtextFeatures>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  pattern?: Maybe<Scalars['String']>;
};

export type IBlueprintFieldRichtextFeatures = {
  __typename: 'BlueprintFieldRichtextFeatures';
  alignCenter?: Maybe<Scalars['Boolean']>;
  alignJustify?: Maybe<Scalars['Boolean']>;
  alignLeft?: Maybe<Scalars['Boolean']>;
  alignRight?: Maybe<Scalars['Boolean']>;
  blockquote?: Maybe<Scalars['Boolean']>;
  bold?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['Boolean']>;
  codeblock?: Maybe<Scalars['Boolean']>;
  frame?: Maybe<Scalars['Boolean']>;
  h1?: Maybe<Scalars['Boolean']>;
  h2?: Maybe<Scalars['Boolean']>;
  h3?: Maybe<Scalars['Boolean']>;
  h4?: Maybe<Scalars['Boolean']>;
  h5?: Maybe<Scalars['Boolean']>;
  h6?: Maybe<Scalars['Boolean']>;
  italic?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['Boolean']>;
  ol?: Maybe<Scalars['Boolean']>;
  table?: Maybe<Scalars['Boolean']>;
  ul?: Maybe<Scalars['Boolean']>;
};

export type IBlueprintFieldRichtextFeaturesInput = {
  alignCenter?: InputMaybe<Scalars['Boolean']>;
  alignJustify?: InputMaybe<Scalars['Boolean']>;
  alignLeft?: InputMaybe<Scalars['Boolean']>;
  alignRight?: InputMaybe<Scalars['Boolean']>;
  blockquote?: InputMaybe<Scalars['Boolean']>;
  bold?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['Boolean']>;
  codeblock?: InputMaybe<Scalars['Boolean']>;
  frame?: InputMaybe<Scalars['Boolean']>;
  h1?: InputMaybe<Scalars['Boolean']>;
  h2?: InputMaybe<Scalars['Boolean']>;
  h3?: InputMaybe<Scalars['Boolean']>;
  h4?: InputMaybe<Scalars['Boolean']>;
  h5?: InputMaybe<Scalars['Boolean']>;
  h6?: InputMaybe<Scalars['Boolean']>;
  italic?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['Boolean']>;
  ol?: InputMaybe<Scalars['Boolean']>;
  table?: InputMaybe<Scalars['Boolean']>;
  ul?: InputMaybe<Scalars['Boolean']>;
};

export type IBlueprintFieldRichtextInput = {
  assetBlueprintIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  assetLinking?: InputMaybe<Scalars['Boolean']>;
  componentBlueprintIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  componentLinking?: InputMaybe<Scalars['Boolean']>;
  documentBlueprintIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  documentLinking?: InputMaybe<Scalars['Boolean']>;
  features?: InputMaybe<IBlueprintFieldRichtextFeaturesInput>;
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
  pattern?: InputMaybe<Scalars['String']>;
};

export type IBlueprintFieldSelect = {
  __typename: 'BlueprintFieldSelect';
  items?: Maybe<Array<Maybe<IBlueprintFieldSelectItem>>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  multiple?: Maybe<Scalars['Boolean']>;
  outputEnumType?: Maybe<Scalars['Boolean']>;
};

export type IBlueprintFieldSelectInput = {
  items?: InputMaybe<Array<InputMaybe<IBlueprintFieldSelectItemInput>>>;
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
  multiple?: InputMaybe<Scalars['Boolean']>;
  outputEnumType?: InputMaybe<Scalars['Boolean']>;
};

export type IBlueprintFieldSelectItem = {
  __typename: 'BlueprintFieldSelectItem';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type IBlueprintFieldSelectItemInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type IBlueprintFieldString = {
  __typename: 'BlueprintFieldString';
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  multiline?: Maybe<Scalars['Boolean']>;
  pattern?: Maybe<Scalars['String']>;
};

export type IBlueprintFieldStringInput = {
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
  multiline?: InputMaybe<Scalars['Boolean']>;
  pattern?: InputMaybe<Scalars['String']>;
};

export type IBlueprintFieldTag = {
  __typename: 'BlueprintFieldTag';
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  pattern?: Maybe<Scalars['String']>;
};

export type IBlueprintFieldTagInput = {
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
  pattern?: InputMaybe<Scalars['String']>;
};

export enum IBlueprintFieldType {
  BlueprintFieldTypeBoolean = 'BLUEPRINT_FIELD_TYPE_BOOLEAN',
  BlueprintFieldTypeCode = 'BLUEPRINT_FIELD_TYPE_CODE',
  BlueprintFieldTypeColor = 'BLUEPRINT_FIELD_TYPE_COLOR',
  BlueprintFieldTypeConnection = 'BLUEPRINT_FIELD_TYPE_CONNECTION',
  BlueprintFieldTypeDatetime = 'BLUEPRINT_FIELD_TYPE_DATETIME',
  BlueprintFieldTypeExtension = 'BLUEPRINT_FIELD_TYPE_EXTENSION',
  BlueprintFieldTypeFile = 'BLUEPRINT_FIELD_TYPE_FILE',
  BlueprintFieldTypeFloat = 'BLUEPRINT_FIELD_TYPE_FLOAT',
  BlueprintFieldTypeGeopoint = 'BLUEPRINT_FIELD_TYPE_GEOPOINT',
  BlueprintFieldTypeInt = 'BLUEPRINT_FIELD_TYPE_INT',
  BlueprintFieldTypeRichtext = 'BLUEPRINT_FIELD_TYPE_RICHTEXT',
  BlueprintFieldTypeSelect = 'BLUEPRINT_FIELD_TYPE_SELECT',
  BlueprintFieldTypeString = 'BLUEPRINT_FIELD_TYPE_STRING',
  BlueprintFieldTypeTag = 'BLUEPRINT_FIELD_TYPE_TAG',
  BlueprintFieldTypeUnspecified = 'BLUEPRINT_FIELD_TYPE_UNSPECIFIED',
  BlueprintFieldTypeVideo = 'BLUEPRINT_FIELD_TYPE_VIDEO'
}

export type IBlueprintFieldVideo = {
  __typename: 'BlueprintFieldVideo';
  pattern?: Maybe<Scalars['String']>;
};

export type IBlueprintFieldVideoInput = {
  pattern?: InputMaybe<Scalars['String']>;
};

export type IBlueprintGroup = {
  __typename: 'BlueprintGroup';
  blueprintGroupId?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Maybe<IBlueprintField>>>;
  name?: Maybe<Scalars['String']>;
};

export type IBlueprintGroupInput = {
  blueprintGroupId?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<Array<InputMaybe<IBlueprintFieldInput>>>;
  name?: InputMaybe<Scalars['String']>;
};

export type IBlueprintGroupInputInput = {
  blueprintGroupId?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<Array<InputMaybe<IBlueprintFieldInputInput>>>;
  name?: InputMaybe<Scalars['String']>;
};

export type IBlueprintInputInput = {
  description?: InputMaybe<Scalars['String']>;
  exposeMutations?: InputMaybe<Scalars['Boolean']>;
  groups?: InputMaybe<Array<InputMaybe<IBlueprintGroupInputInput>>>;
  name?: InputMaybe<Scalars['String']>;
  previewImageUrl?: InputMaybe<Scalars['String']>;
  single?: InputMaybe<Scalars['Boolean']>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<IBlueprintVariant>;
};

export type IBlueprintResponse = {
  __typename: 'BlueprintResponse';
  blueprintId?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  exposeMutations?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<Array<Maybe<IBlueprintGroup>>>;
  name?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  single?: Maybe<Scalars['Boolean']>;
  system?: Maybe<Scalars['Boolean']>;
  tagIds?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<Scalars['String']>;
  variant?: Maybe<IBlueprintVariant>;
};

export type IBlueprintResponseConnection = {
  __typename: 'BlueprintResponseConnection';
  edges?: Maybe<Array<Maybe<IBlueprintResponseConnectionEdge>>>;
  pageInfo?: Maybe<IPageInfo>;
  totalCount: Scalars['Int'];
};

export type IBlueprintResponseConnectionEdge = {
  __typename: 'BlueprintResponseConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<IBlueprintResponse>;
};

export type IBlueprintResponseInput = {
  blueprintId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  exposeMutations?: InputMaybe<Scalars['Boolean']>;
  groups?: InputMaybe<Array<InputMaybe<IBlueprintGroupInput>>>;
  name?: InputMaybe<Scalars['String']>;
  previewImageUrl?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  single?: InputMaybe<Scalars['Boolean']>;
  system?: InputMaybe<Scalars['Boolean']>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<IBlueprintVariant>;
};

export type IBlueprintUpsertInputInput = {
  blueprintId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  exposeMutations?: InputMaybe<Scalars['Boolean']>;
  groups?: InputMaybe<Array<InputMaybe<IBlueprintGroupInputInput>>>;
  name?: InputMaybe<Scalars['String']>;
  previewImageUrl?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  single?: InputMaybe<Scalars['Boolean']>;
  system?: InputMaybe<Scalars['Boolean']>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<IBlueprintVariant>;
};

export enum IBlueprintVariant {
  BlueprintVariantAsset = 'BLUEPRINT_VARIANT_ASSET',
  BlueprintVariantComponent = 'BLUEPRINT_VARIANT_COMPONENT',
  BlueprintVariantDocument = 'BLUEPRINT_VARIANT_DOCUMENT',
  BlueprintVariantTemplate = 'BLUEPRINT_VARIANT_TEMPLATE',
  BlueprintVariantUnspecified = 'BLUEPRINT_VARIANT_UNSPECIFIED'
}

export type ICaisyUserProfile = {
  __typename: 'CaisyUserProfile';
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  photoURL?: Maybe<Scalars['String']>;
};

export enum ICanCannotEnum {
  Can = 'CAN',
  Cannot = 'CANNOT'
}

export type ICheckBlueprintNameAvailableRequest = {
  blueprintName?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type ICheckBlueprintNameAvailableResponse = {
  __typename: 'CheckBlueprintNameAvailableResponse';
  available?: Maybe<Scalars['Boolean']>;
};

export type ICommentFilterInput = {
  mentionedUsers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<ICommentStatus>;
};

export type ICommentInput = {
  blueprintFieldId?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  documentId?: InputMaybe<Scalars['String']>;
  mentionedUsers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  priority?: InputMaybe<Scalars['Int']>;
  projectId: Scalars['String'];
  replyToCommentId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ICommentResponse = {
  __typename: 'CommentResponse';
  blueprintFieldId?: Maybe<Scalars['String']>;
  commentId: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Time']>;
  createdBy: Scalars['String'];
  documentId?: Maybe<Scalars['String']>;
  doneAt?: Maybe<Scalars['Time']>;
  doneBy?: Maybe<Scalars['String']>;
  mentionedUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  priority?: Maybe<Scalars['Int']>;
  projectId: Scalars['String'];
  replyToCommentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Time']>;
};

export type ICommentResponseConnection = {
  __typename: 'CommentResponseConnection';
  edges?: Maybe<Array<Maybe<ICommentResponseConnectionEdge>>>;
  pageInfo?: Maybe<IPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ICommentResponseConnectionEdge = {
  __typename: 'CommentResponseConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ICommentResponse>;
};

export enum ICommentStatus {
  Done = 'DONE',
  Undone = 'UNDONE'
}

export type ICopyMemberFromProjectToProjectError = {
  __typename: 'CopyMemberFromProjectToProjectError';
  errorMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ICopyMemberFromProjectToProjectRequestInput = {
  sourceProjectId?: InputMaybe<Scalars['String']>;
  targetProjectId?: InputMaybe<Scalars['String']>;
};

export type ICopyMemberFromProjectToProjectResponse = {
  __typename: 'CopyMemberFromProjectToProjectResponse';
  errors?: Maybe<Array<Maybe<ICopyMemberFromProjectToProjectError>>>;
  successfulMembers?: Maybe<Scalars['Int']>;
  totalMembers?: Maybe<Scalars['Int']>;
};

export type ICreateApiKeyRequestInput = {
  apikey?: InputMaybe<IApiKeyInput>;
  projectId: Scalars['String'];
};

export type ICreateApiKeyResponse = {
  __typename: 'CreateAPIKeyResponse';
  apikey?: Maybe<IApiKeyInitalResponse>;
  projectId?: Maybe<Scalars['String']>;
};

export type ICreateBlueprintRequest = {
  input?: InputMaybe<IBlueprintInputInput>;
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ICreateBlueprintResponse = {
  __typename: 'CreateBlueprintResponse';
  blueprint?: Maybe<IBlueprintResponse>;
};

export type ICreateCaisyUserResult = {
  __typename: 'CreateCaisyUserResult';
  userId?: Maybe<Scalars['ID']>;
};

export type ICreateCheckoutSessionRequestInput = {
  organizationId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ICreateCheckoutSessionResponse = {
  __typename: 'CreateCheckoutSessionResponse';
  redirectUrl?: Maybe<Scalars['String']>;
};

export type ICreateCommentRequestInput = {
  comment: ICommentInput;
};

export type ICreateCommentResponse = {
  __typename: 'CreateCommentResponse';
  comment?: Maybe<ICommentResponse>;
};

export type ICreateDocumentFieldLocaleRequestInput = {
  input?: InputMaybe<IDocumentFieldLocaleInputInput>;
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ICreateDocumentFieldLocaleResponse = {
  __typename: 'CreateDocumentFieldLocaleResponse';
  documentFieldLocale?: Maybe<IDocumentFieldLocaleResponse>;
  projectId?: Maybe<Scalars['String']>;
};

export type ICreateDocumentRequestInput = {
  blueprintBranch?: InputMaybe<Scalars['String']>;
  blueprintId?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ICreateDocumentResponse = {
  __typename: 'CreateDocumentResponse';
  document?: Maybe<IDocument>;
};

export type ICreateGroupRequestInput = {
  group?: InputMaybe<IGroupInputInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ICreateGroupResponse = {
  __typename: 'CreateGroupResponse';
  group?: Maybe<IGroupResponse>;
};

export type ICreateManyViewsRequestInput = {
  input?: InputMaybe<Array<InputMaybe<IViewCreateInputInput>>>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type ICreateManyViewsResponse = {
  __typename: 'CreateManyViewsResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type ICreateNotificationRequestInput = {
  notification: INotificationInput;
};

export type ICreateNotificationResponse = {
  __typename: 'CreateNotificationResponse';
  notification: INotificationResponse;
};

export type ICreateOrganizationRequestInput = {
  organization?: InputMaybe<IOrganizationInputInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ICreateOrganizationResponse = {
  __typename: 'CreateOrganizationResponse';
  organization?: Maybe<IOrganizationResponse>;
};

export type ICreatePortalSessionRequestInput = {
  organizationId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ICreatePortalSessionResponse = {
  __typename: 'CreatePortalSessionResponse';
  redirectUrl?: Maybe<Scalars['String']>;
};

export type ICreatePreviewItemRequestInput = {
  input?: InputMaybe<IPreviewItemInputInput>;
  previewId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type ICreatePreviewItemResponse = {
  __typename: 'CreatePreviewItemResponse';
  previewItem?: Maybe<IPreviewItemResponse>;
};

export type ICreatePreviewRequestInput = {
  input?: InputMaybe<IPreviewInputInput>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type ICreatePreviewResponse = {
  __typename: 'CreatePreviewResponse';
  preview?: Maybe<IPreviewResponse>;
};

export type ICreateProjectRequestInput = {
  organizationId?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<IProjectInputInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ICreateProjectResponse = {
  __typename: 'CreateProjectResponse';
  project?: Maybe<IProjectResponse>;
};

export type ICreateReleaseRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
  release?: InputMaybe<IReleaseInputInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ICreateReleaseResponse = {
  __typename: 'CreateReleaseResponse';
  release?: Maybe<IReleaseResponse>;
};

export type ICreateRoleRuleRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  roleRule?: InputMaybe<IRoleRuleInputInput>;
};

export type ICreateRoleRuleResponse = {
  __typename: 'CreateRoleRuleResponse';
  roleRule?: Maybe<IRoleRuleResponse>;
};

export type ICreateTagInput = {
  color?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  referenceType?: InputMaybe<IReferenceType>;
};

export type ICreateTagRequest = {
  tag?: InputMaybe<ICreateTagInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ICreateTagResponse = {
  __typename: 'CreateTagResponse';
  tag?: Maybe<ITagResponse>;
};

export type ICreateUserAccessTokenRequestInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type ICreateUserAccessTokenResponse = {
  __typename: 'CreateUserAccessTokenResponse';
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ICreateUserRoleRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  userRole?: InputMaybe<IUserRoleInputInput>;
};

export type ICreateUserRoleResponse = {
  __typename: 'CreateUserRoleResponse';
  userRole?: Maybe<IUserRoleResponse>;
};

export type ICreateViewPinRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  viewId?: InputMaybe<Scalars['String']>;
};

export type ICreateViewPinResponse = {
  __typename: 'CreateViewPinResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type ICreateViewRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
  view?: InputMaybe<IViewCreateInputInput>;
};

export type ICreateViewResponse = {
  __typename: 'CreateViewResponse';
  view?: Maybe<IViewResponse>;
};

export type ICreateWebhookRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
  webhook?: InputMaybe<IWebhookInputInput>;
};

export type ICreateWebhookResponse = {
  __typename: 'CreateWebhookResponse';
  webhook?: Maybe<IWebhookResponse>;
};

export type IDateInput = {
  day?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type IDeActivateOrganizationMemberRequestInput = {
  organizationId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IDeActivateOrganizationMemberResponse = {
  __typename: 'DeActivateOrganizationMemberResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type IDeAssignManyRolesRequestInput = {
  requests?: InputMaybe<Array<InputMaybe<IDeAssignRoleRequestInput>>>;
};

export type IDeAssignManyRolesResponse = {
  __typename: 'DeAssignManyRolesResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type IDeAssignRoleRequestInput = {
  emailAddress?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IDeAssignRoleResponse = {
  __typename: 'DeAssignRoleResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type IDeleteApiKeyRequestInput = {
  apiKeyId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IDeleteApiKeyResponse = {
  __typename: 'DeleteAPIKeyResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type IDeleteBlueprintRequest = {
  blueprintId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IDeleteBlueprintResponse = {
  __typename: 'DeleteBlueprintResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type IDeleteCommentRequestInput = {
  commentId: Scalars['String'];
  projectId: Scalars['String'];
};

export type IDeleteCommentResponse = {
  __typename: 'DeleteCommentResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type IDeleteDocumentError = {
  __typename: 'DeleteDocumentError';
  documentId?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type IDeleteDocumentFieldLocaleRequestInput = {
  documentFieldLocaleId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IDeleteDocumentFieldLocaleResponse = {
  __typename: 'DeleteDocumentFieldLocaleResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type IDeleteDocumentRequestInput = {
  blueprintBranch?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IDeleteDocumentResponse = {
  __typename: 'DeleteDocumentResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type IDeleteEntrypointRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IDeleteEntrypointResponse = {
  __typename: 'DeleteEntrypointResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type IDeleteGroupRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
};

export type IDeleteGroupResponse = {
  __typename: 'DeleteGroupResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type IDeleteManyDocumentsRequestInput = {
  deleteDocumentRequests?: InputMaybe<Array<InputMaybe<IDeleteDocumentRequestInput>>>;
};

export type IDeleteManyDocumentsResponse = {
  __typename: 'DeleteManyDocumentsResponse';
  batchErrors?: Maybe<Array<Maybe<IDeleteDocumentError>>>;
  deletedDocumentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IDeleteManyReleasesRequestInput = {
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IDeleteManyReleasesResponse = {
  __typename: 'DeleteManyReleasesResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type IDeleteOrganizationRequestInput = {
  organizationId?: InputMaybe<Scalars['String']>;
};

export type IDeleteOrganizationResponse = {
  __typename: 'DeleteOrganizationResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type IDeletePreviewItemRequestInput = {
  previewId?: InputMaybe<Scalars['String']>;
  previewItemId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IDeletePreviewItemResponse = {
  __typename: 'DeletePreviewItemResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type IDeletePreviewRequestInput = {
  previewId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IDeletePreviewResponse = {
  __typename: 'DeletePreviewResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type IDeleteProjectRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
};

export type IDeleteProjectResponse = {
  __typename: 'DeleteProjectResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type IDeleteRoleRuleRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  ruleId?: InputMaybe<Scalars['String']>;
};

export type IDeleteRoleRuleResponse = {
  __typename: 'DeleteRoleRuleResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type IDeleteTagRequest = {
  projectId?: InputMaybe<Scalars['String']>;
  tagId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IDeleteTagResponse = {
  __typename: 'DeleteTagResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type IDeleteUserAccessTokenRequestInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type IDeleteUserAccessTokenResponse = {
  __typename: 'DeleteUserAccessTokenResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type IDeleteUserFromGroupCascadingRequestInput = {
  groupId: Scalars['String'];
  memberId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IDeleteUserFromGroupCascadingResponse = {
  __typename: 'DeleteUserFromGroupCascadingResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type IDeleteUserFromOrganizationRequestInput = {
  memberId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  triggeringUserId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IDeleteUserFromOrganizationResponse = {
  __typename: 'DeleteUserFromOrganizationResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type IDeleteUserRoleRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['String']>;
};

export type IDeleteUserRoleResponse = {
  __typename: 'DeleteUserRoleResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type IDeleteViewPinRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  viewId?: InputMaybe<Scalars['String']>;
};

export type IDeleteViewPinResponse = {
  __typename: 'DeleteViewPinResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type IDeleteViewRequestInput = {
  id?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IDeleteViewResponse = {
  __typename: 'DeleteViewResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type IDeleteWebhookRequestInput = {
  id?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IDeleteWebhookResponse = {
  __typename: 'DeleteWebhookResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type IDocument = {
  __typename: 'Document';
  archivedAt?: Maybe<Scalars['Time']>;
  blueprintBranch?: Maybe<Scalars['String']>;
  blueprintId?: Maybe<Scalars['String']>;
  blueprintVariant?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Time']>;
  createdByUserId?: Maybe<Scalars['String']>;
  documentId?: Maybe<Scalars['String']>;
  environmentId?: Maybe<Scalars['String']>;
  firstPublishedAt?: Maybe<Scalars['Time']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['Time']>;
  statusId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  unpublishedAt?: Maybe<Scalars['Time']>;
  updatedAt?: Maybe<Scalars['Time']>;
};

export type IDocumentCountsByBlueprintsResponse = {
  __typename: 'DocumentCountsByBlueprintsResponse';
  blueprintId?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type IDocumentField = {
  __typename: 'DocumentField';
  blueprintFieldId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Time']>;
  data?: Maybe<Scalars['Any']>;
  documentFieldLocaleId?: Maybe<Scalars['String']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  type?: Maybe<IBlueprintFieldType>;
  updatedAt?: Maybe<Scalars['Time']>;
};

export type IDocumentFieldFilterFieldInput = {
  compare?: InputMaybe<IDocumentFilterCompareField>;
  fieldId?: InputMaybe<Scalars['String']>;
  fieldType?: InputMaybe<Scalars['String']>;
  fieldValue?: InputMaybe<Scalars['String']>;
};

export type IDocumentFieldFilterInput = {
  and?: InputMaybe<Array<InputMaybe<IDocumentFieldFilterInput>>>;
  field?: InputMaybe<IDocumentFieldFilterFieldInput>;
  or?: InputMaybe<Array<InputMaybe<IDocumentFieldFilterInput>>>;
};

export type IDocumentFieldInput = {
  blueprintFieldId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Time']>;
  data?: InputMaybe<Scalars['Any']>;
  documentFieldLocaleId?: InputMaybe<Scalars['String']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<IBlueprintFieldType>;
  updatedAt?: InputMaybe<Scalars['Time']>;
};

export type IDocumentFieldLocaleChangeSet = {
  __typename: 'DocumentFieldLocaleChangeSet';
  sourceDocumentFieldLocaleId?: Maybe<Scalars['String']>;
  targetDocumentFieldLocaleId?: Maybe<Scalars['String']>;
};

export type IDocumentFieldLocaleInputInput = {
  allowEmptyRequired?: InputMaybe<Scalars['Boolean']>;
  apiName: Scalars['String'];
  default?: InputMaybe<Scalars['Boolean']>;
  disableEditing?: InputMaybe<Scalars['Boolean']>;
  disableInResponse?: InputMaybe<Scalars['Boolean']>;
  fallbackLocaleId?: InputMaybe<Scalars['String']>;
  flag?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type IDocumentFieldLocaleResponse = {
  __typename: 'DocumentFieldLocaleResponse';
  allowEmptyRequired?: Maybe<Scalars['Boolean']>;
  apiName?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  disableEditing?: Maybe<Scalars['Boolean']>;
  disableInResponse?: Maybe<Scalars['Boolean']>;
  fallbackLocaleId?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type IDocumentFieldLocaleUpsertInputInput = {
  allowEmptyRequired?: InputMaybe<Scalars['Boolean']>;
  apiName?: InputMaybe<Scalars['String']>;
  default?: InputMaybe<Scalars['Boolean']>;
  disableEditing?: InputMaybe<Scalars['Boolean']>;
  disableInResponse?: InputMaybe<Scalars['Boolean']>;
  documentFieldLocaleId?: InputMaybe<Scalars['String']>;
  fallbackLocaleId?: InputMaybe<Scalars['String']>;
  flag?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type IDocumentFieldResponse = {
  __typename: 'DocumentFieldResponse';
  field?: Maybe<IDocumentField>;
  validation?: Maybe<IValidation>;
};

export enum IDocumentFilterCompareDate {
  DateIsGreater = 'DATE_IS_GREATER',
  DateIsLess = 'DATE_IS_LESS'
}

export enum IDocumentFilterCompareField {
  FieldContains = 'FIELD_CONTAINS',
  FieldIs = 'FIELD_IS',
  FieldIsEqualOrGreater = 'FIELD_IS_EQUAL_OR_GREATER',
  FieldIsEqualOrLess = 'FIELD_IS_EQUAL_OR_LESS',
  FieldIsGreater = 'FIELD_IS_GREATER',
  FieldIsLess = 'FIELD_IS_LESS',
  FieldIsNot = 'FIELD_IS_NOT'
}

export enum IDocumentFilterComparePure {
  PureIs = 'PURE_IS',
  PureIsNot = 'PURE_IS_NOT'
}

export type IDocumentMetaFilterBlueprintInput = {
  blueprintId?: InputMaybe<Scalars['String']>;
  compare?: InputMaybe<IDocumentFilterComparePure>;
};

export type IDocumentMetaFilterCreatedAtInput = {
  compare?: InputMaybe<IDocumentFilterCompareDate>;
  value?: InputMaybe<Scalars['String']>;
};

export type IDocumentMetaFilterCreatedByInput = {
  compare?: InputMaybe<IDocumentFilterComparePure>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IDocumentMetaFilterFirstPublishedAtInput = {
  compare?: InputMaybe<IDocumentFilterCompareDate>;
  value?: InputMaybe<Scalars['String']>;
};

export type IDocumentMetaFilterInput = {
  and?: InputMaybe<Array<InputMaybe<IDocumentMetaFilterInput>>>;
  blueprint?: InputMaybe<IDocumentMetaFilterBlueprintInput>;
  createdAt?: InputMaybe<IDocumentMetaFilterCreatedAtInput>;
  createdBy?: InputMaybe<IDocumentMetaFilterCreatedByInput>;
  firstPublishedAt?: InputMaybe<IDocumentMetaFilterFirstPublishedAtInput>;
  lastPublishedAt?: InputMaybe<IDocumentMetaFilterLastPublishedAtInput>;
  lastUpdatedAt?: InputMaybe<IDocumentMetaFilterLastUpdatedAtInput>;
  lastUpdatedBy?: InputMaybe<IDocumentMetaFilterLastUpdatedByInput>;
  or?: InputMaybe<Array<InputMaybe<IDocumentMetaFilterInput>>>;
  status?: InputMaybe<IDocumentMetaFilterStatusInput>;
  variant?: InputMaybe<IDocumentMetaFilterVariantInput>;
};

export type IDocumentMetaFilterLastPublishedAtInput = {
  compare?: InputMaybe<IDocumentFilterCompareDate>;
  value?: InputMaybe<Scalars['String']>;
};

export type IDocumentMetaFilterLastUpdatedAtInput = {
  compare?: InputMaybe<IDocumentFilterCompareDate>;
  value?: InputMaybe<Scalars['String']>;
};

export type IDocumentMetaFilterLastUpdatedByInput = {
  compare?: InputMaybe<IDocumentFilterComparePure>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IDocumentMetaFilterStatusInput = {
  compare?: InputMaybe<IDocumentFilterComparePure>;
  statusId?: InputMaybe<Scalars['String']>;
};

export type IDocumentMetaFilterVariantInput = {
  compare?: InputMaybe<IDocumentFilterComparePure>;
  variant?: InputMaybe<IBlueprintVariant>;
};

export type IDocumentResponseConnection = {
  __typename: 'DocumentResponseConnection';
  edges?: Maybe<Array<Maybe<IDocumentResponseConnectionEdge>>>;
  pageInfo?: Maybe<IPageInfo>;
  totalCount?: Maybe<Scalars['BigInt']>;
};

export type IDocumentResponseConnectionEdge = {
  __typename: 'DocumentResponseConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<IDocumentWithFieldsResponse>;
};

export type IDocumentResponseInput = {
  archivedAt?: InputMaybe<Scalars['Time']>;
  blueprintBranch?: InputMaybe<Scalars['String']>;
  blueprintId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Time']>;
  createdByUserId?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['String']>;
  previewImageUrl?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['Time']>;
  statusId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Time']>;
};

export type IDocumentSnapshotListFragment = {
  __typename: 'DocumentSnapshotListFragment';
  publishedAt?: Maybe<Scalars['Time']>;
  snapshotId?: Maybe<Scalars['String']>;
};

export enum IDocumentSortDirection {
  DirectionAsc = 'DIRECTION_ASC',
  DirectionDesc = 'DIRECTION_DESC'
}

export type IDocumentSortInput = {
  blueprint?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdBy?: InputMaybe<Scalars['Boolean']>;
  direction?: InputMaybe<IDocumentSortDirection>;
  field?: InputMaybe<IFieldSelector>;
  firstPublishedAt?: InputMaybe<Scalars['Boolean']>;
  lastPublishedAt?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedAt?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedBy?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
};

export type IDocumentStatusResponse = {
  __typename: 'DocumentStatusResponse';
  name?: Maybe<Scalars['String']>;
  statusId?: Maybe<Scalars['Int']>;
};

export type IDocumentValidation = {
  __typename: 'DocumentValidation';
  documentId?: Maybe<Scalars['String']>;
  validation?: Maybe<IValidation>;
};

export type IDocumentWithFieldsInput = {
  archivedAt?: InputMaybe<Scalars['Time']>;
  blueprintBranch?: InputMaybe<Scalars['String']>;
  blueprintId?: InputMaybe<Scalars['String']>;
  blueprintVariant?: InputMaybe<IBlueprintVariant>;
  createdAt?: InputMaybe<Scalars['Time']>;
  createdByUserId?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<Array<InputMaybe<IDocumentFieldInput>>>;
  lastUpdatedByUserId?: InputMaybe<Scalars['String']>;
  previewImageUrl?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['Time']>;
  statusId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Time']>;
};

export type IDocumentWithFieldsResponse = {
  __typename: 'DocumentWithFieldsResponse';
  archivedAt?: Maybe<Scalars['Time']>;
  blueprintBranch?: Maybe<Scalars['String']>;
  blueprintId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Time']>;
  createdByUserId?: Maybe<Scalars['String']>;
  documentId?: Maybe<Scalars['String']>;
  environmentId?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Maybe<IDocumentField>>>;
  firstPublishedAt?: Maybe<Scalars['Time']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['Time']>;
  statusId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  unpublishedAt?: Maybe<Scalars['Time']>;
  updatedAt?: Maybe<Scalars['Time']>;
};

export type IDuplicateDocumentRequestInput = {
  blueprintBranch?: InputMaybe<Scalars['String']>;
  blueprintId?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IDuplicateDocumentResponse = {
  __typename: 'DuplicateDocumentResponse';
  blueprintBranch?: Maybe<Scalars['String']>;
  environmentId?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  response?: Maybe<IDocument>;
};

export type IDuplicateToProjectRequestInput = {
  overwriteDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  projectId?: InputMaybe<Scalars['String']>;
  selection?: InputMaybe<IProjectPortSelectionInput>;
  source?: InputMaybe<IDuplicateToProjectSourceInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IDuplicateToProjectResponse = {
  __typename: 'DuplicateToProjectResponse';
  portId?: Maybe<Scalars['String']>;
};

export type IDuplicateToProjectSourceInput = {
  projectId?: InputMaybe<Scalars['String']>;
};

export type IEmailRecord = {
  __typename: 'EmailRecord';
  address?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type IEntrypointInput = {
  data?: InputMaybe<Scalars['Map']>;
  domain?: InputMaybe<Scalars['String']>;
};

export type IEntrypointResponse = {
  __typename: 'EntrypointResponse';
  data?: Maybe<Scalars['Map']>;
  domain?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  lastUpdatedAt?: Maybe<Scalars['String']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
};

export type IExcludeFieldsSelectionInput = {
  blueprintFieldId?: InputMaybe<IExcludeSelectionInput>;
  documentFieldLocaleId?: InputMaybe<IExcludeSelectionInput>;
};

export type IExcludeSelectionInput = {
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type IFieldSelector = {
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type IGetAllAmountsRequestInput = {
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetAllAmountsResponse = {
  __typename: 'GetAllAmountsResponse';
  amounts?: Maybe<Array<Maybe<IAmountItem>>>;
};

export type IGetAllDocumentFieldLocaleRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetAllDocumentFieldLocaleResponse = {
  __typename: 'GetAllDocumentFieldLocaleResponse';
  documentFieldLocales?: Maybe<Array<Maybe<IDocumentFieldLocaleResponse>>>;
  projectId?: Maybe<Scalars['String']>;
};

export type IGetAllDocumentSnapshotRequestInput = {
  documentId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetAllDocumentSnapshotResponse = {
  __typename: 'GetAllDocumentSnapshotResponse';
  documentSnapshots?: Maybe<Array<Maybe<IDocumentSnapshotListFragment>>>;
};

export type IGetAllDocumentStatusRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetAllDocumentStatusResponse = {
  __typename: 'GetAllDocumentStatusResponse';
  documentStatuses?: Maybe<Array<Maybe<IDocumentStatusResponse>>>;
};

export type IGetAllWebhooksRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetAllWebhooksResponse = {
  __typename: 'GetAllWebhooksResponse';
  webhooks?: Maybe<Array<Maybe<IWebhookResponse>>>;
};

export type IGetAmountRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetAmountResponse = {
  __typename: 'GetAmountResponse';
  value?: Maybe<Scalars['Int']>;
};

export type IGetBlueprintByIdRequest = {
  blueprintId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetBlueprintByIdResponse = {
  __typename: 'GetBlueprintByIDResponse';
  blueprint?: Maybe<IBlueprintResponse>;
};

export type IGetBlueprintByNameRequest = {
  blueprintName?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetBlueprintByNameResponse = {
  __typename: 'GetBlueprintByNameResponse';
  blueprint?: Maybe<IBlueprintResponse>;
};

export type IGetCommentByIdRequestInput = {
  commentId: Scalars['String'];
  projectId: Scalars['String'];
};

export type IGetCommentByIdResponse = {
  __typename: 'GetCommentByIdResponse';
  comment?: Maybe<ICommentResponse>;
};

export type IGetCountsRequestInput = {
  filter?: InputMaybe<IQuotaFilterInput>;
  pagination?: InputMaybe<IQuotaPaginationInput>;
};

export type IGetCountsResponse = {
  __typename: 'GetCountsResponse';
  counts?: Maybe<Array<Maybe<IQuotaCount>>>;
};

export type IGetDocumentByIdRequestInput = {
  blueprintBranch?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  requestMode?: InputMaybe<IGetDocumentMode>;
};

export type IGetDocumentByIdResponse = {
  __typename: 'GetDocumentByIdResponse';
  document?: Maybe<IDocumentWithFieldsResponse>;
};

export type IGetDocumentCountsByBlueprintsRequestInput = {
  blueprintBranch?: InputMaybe<Scalars['String']>;
  blueprintId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  environmentId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetDocumentCountsByBlueprintsResponse = {
  __typename: 'GetDocumentCountsByBlueprintsResponse';
  counts?: Maybe<Array<Maybe<IDocumentCountsByBlueprintsResponse>>>;
};

export enum IGetDocumentMode {
  GetDocumentRequestModeLatest = 'GET_DOCUMENT_REQUEST_MODE_LATEST',
  GetDocumentRequestModePublished = 'GET_DOCUMENT_REQUEST_MODE_PUBLISHED'
}

export type IGetDocumentReferencesRequestInput = {
  documentId?: InputMaybe<Scalars['String']>;
  paginationArguments?: InputMaybe<IPaginationArgumentsForwardInput>;
  projectId?: InputMaybe<Scalars['String']>;
  requestMode?: InputMaybe<IGetDocumentMode>;
};

export type IGetDocumentReferencesResponse = {
  __typename: 'GetDocumentReferencesResponse';
  connection?: Maybe<IDocumentResponseConnection>;
};

export type IGetDocumentRequestInput = {
  blueprintBranch?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetDocumentSnapshotRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
  snapshotId?: InputMaybe<Scalars['String']>;
};

export type IGetDocumentSnapshotResponse = {
  __typename: 'GetDocumentSnapshotResponse';
  document?: Maybe<IDocumentWithFieldsResponse>;
};

export type IGetEntrypointByDomainRequestInput = {
  domain?: InputMaybe<Scalars['String']>;
};

export type IGetEntrypointByDomainResponse = {
  __typename: 'GetEntrypointByDomainResponse';
  entrypoint?: Maybe<IEntrypointResponse>;
};

export type IGetEntrypointByScopeRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetEntrypointByScopeResponse = {
  __typename: 'GetEntrypointByScopeResponse';
  entrypoint?: Maybe<IEntrypointResponse>;
};

export type IGetGroupByIdRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
};

export type IGetGroupByIdResponse = {
  __typename: 'GetGroupByIDResponse';
  group?: Maybe<IGroupResponse>;
};

export type IGetGroupByIdWithInheritanceRequestInput = {
  groupId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetGroupByIdWithInheritanceResponse = {
  __typename: 'GetGroupByIDWithInheritanceResponse';
  group?: Maybe<IGroupResponse>;
  organization?: Maybe<IOrganizationResponse>;
};

export type IGetGroupMembersRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
  includeGuests?: InputMaybe<Scalars['Boolean']>;
  paginationArguments?: InputMaybe<IPaginationArgumentsForwardInput>;
};

export type IGetGroupMembersResponse = {
  __typename: 'GetGroupMembersResponse';
  connection?: Maybe<IMemberConnection>;
};

export type IGetGroupMembersWithAggregationRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
  paginationArguments?: InputMaybe<IPaginationArgumentsForwardInput>;
};

export type IGetGroupMembersWithAggregationResponse = {
  __typename: 'GetGroupMembersWithAggregationResponse';
  connection?: Maybe<IGroupMembersWithAggregationConnection>;
};

export type IGetManyApiKeyRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetManyApiKeyResponse = {
  __typename: 'GetManyAPIKeyResponse';
  apiKeys?: Maybe<Array<Maybe<IApiKeyResponse>>>;
  projectId?: Maybe<Scalars['String']>;
};

export type IGetManyAuditEventsRequestInput = {
  paginationArguments?: InputMaybe<IPaginationArgumentsInput>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetManyAuditEventsResponse = {
  __typename: 'GetManyAuditEventsResponse';
  connection?: Maybe<IAuditEventConnection>;
};

export type IGetManyBlueprintsRequest = {
  paginationArguments?: InputMaybe<IPaginationArgumentsForwardInput>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetManyBlueprintsResponse = {
  __typename: 'GetManyBlueprintsResponse';
  connection?: Maybe<IBlueprintResponseConnection>;
};

export type IGetManyCommentsRequestInput = {
  filter?: InputMaybe<ICommentFilterInput>;
  paginationArguments?: InputMaybe<IPaginationArgumentsInput>;
  projectId: Scalars['String'];
};

export type IGetManyCommentsResponse = {
  __typename: 'GetManyCommentsResponse';
  connection?: Maybe<ICommentResponseConnection>;
};

export type IGetManyDocumentFieldsByDocumentIdRequestInput = {
  blueprintBranch?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetManyDocumentFieldsByDocumentIdResponse = {
  __typename: 'GetManyDocumentFieldsByDocumentIdResponse';
  fields?: Maybe<Array<Maybe<IDocumentFieldResponse>>>;
};

export type IGetManyDocumentFieldsByFieldIdsRequestInput = {
  blueprintBranch?: InputMaybe<Scalars['String']>;
  documentFieldIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  documentId?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetManyDocumentFieldsByFieldIdsResponse = {
  __typename: 'GetManyDocumentFieldsByFieldIdsResponse';
  fields?: Maybe<Array<Maybe<IDocumentFieldResponse>>>;
};

export type IGetManyDocumentsByIdsRequestInput = {
  blueprintBranch?: InputMaybe<Scalars['String']>;
  documentIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  environmentId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  requestMode?: InputMaybe<IGetDocumentMode>;
};

export type IGetManyDocumentsByIdsResponse = {
  __typename: 'GetManyDocumentsByIdsResponse';
  documents?: Maybe<Array<Maybe<IDocumentWithFieldsResponse>>>;
};

export type IGetManyDocumentsRequestInput = {
  blueprintBranch?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
  excludeFields?: InputMaybe<IExcludeFieldsSelectionInput>;
  fieldFilter?: InputMaybe<IDocumentFieldFilterInput>;
  fulltextFilter?: InputMaybe<Scalars['String']>;
  metaFilter?: InputMaybe<IDocumentMetaFilterInput>;
  paginationArguments?: InputMaybe<IPaginationArgumentsInput>;
  projectId?: InputMaybe<Scalars['String']>;
  requestMode?: InputMaybe<IGetDocumentMode>;
  sort?: InputMaybe<Array<InputMaybe<IDocumentSortInput>>>;
};

export type IGetManyDocumentsResponse = {
  __typename: 'GetManyDocumentsResponse';
  connection?: Maybe<IDocumentResponseConnection>;
};

export type IGetManyGroupsByUserIdRequestFilterInput = {
  organizationId?: InputMaybe<Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type IGetManyGroupsByUserIdRequestInput = {
  filter?: InputMaybe<IGetManyGroupsByUserIdRequestFilterInput>;
  paginationArguments?: InputMaybe<IPaginationArgumentsInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetManyGroupsByUserIdResponse = {
  __typename: 'GetManyGroupsByUserIDResponse';
  connection?: Maybe<IGroupResponseConnection>;
};

export type IGetManyInvitationsRequestInput = {
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetManyInvitationsResponse = {
  __typename: 'GetManyInvitationsResponse';
  invitations?: Maybe<Array<Maybe<IInvitations>>>;
};

export type IGetManyNotificationsFilterInput = {
  category?: InputMaybe<INotificationCategory>;
  endDate?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  read?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type IGetManyNotificationsRequestInput = {
  filter?: InputMaybe<IGetManyNotificationsFilterInput>;
  paginationArguments?: InputMaybe<IPaginationArgumentsInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetManyNotificationsResponse = {
  __typename: 'GetManyNotificationsResponse';
  connection: INotificationResponseConnection;
};

export type IGetManyOrganizationsByUserIdRequestFilterInput = {
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type IGetManyOrganizationsByUserIdRequestInput = {
  filter?: InputMaybe<IGetManyOrganizationsByUserIdRequestFilterInput>;
  paginationArguments?: InputMaybe<IPaginationArgumentsInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetManyOrganizationsByUserIdResponse = {
  __typename: 'GetManyOrganizationsByUserIDResponse';
  connection?: Maybe<IOrganizationResponseConnection>;
};

export type IGetManyPreviewsRequestInput = {
  paginationArguments?: InputMaybe<IPaginationArgumentsInput>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetManyPreviewsResponse = {
  __typename: 'GetManyPreviewsResponse';
  connection?: Maybe<IPreviewConnection>;
};

export type IGetManyProjectQuotaOverviewByIdRequestInput = {
  organizationId: Scalars['String'];
  projectIds: Array<Scalars['String']>;
};

export type IGetManyProjectQuotaOverviewByIdResponse = {
  __typename: 'GetManyProjectQuotaOverviewByIDResponse';
  quotaOverviews?: Maybe<Array<Maybe<IQuotaOverviewReponse>>>;
};

export type IGetManyProjectsByUserIdRequestFilterInput = {
  groupId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type IGetManyProjectsByUserIdRequestInput = {
  filter?: InputMaybe<IGetManyProjectsByUserIdRequestFilterInput>;
  paginationArguments?: InputMaybe<IPaginationArgumentsInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetManyProjectsByUserIdResponse = {
  __typename: 'GetManyProjectsByUserIDResponse';
  connection?: Maybe<IProjectResponseConnection>;
};

export type IGetManyReleasesByProjectIdRequestInput = {
  filter?: InputMaybe<IReleaseFilterInput>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetManyReleasesByProjectIdResponse = {
  __typename: 'GetManyReleasesByProjectIdResponse';
  node?: Maybe<Array<Maybe<IReleaseResponse>>>;
};

export type IGetManyRoleRulesByRoleIdRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['String']>;
};

export type IGetManyRoleRulesByRoleIdResponse = {
  __typename: 'GetManyRoleRulesByRoleIDResponse';
  roleRules?: Maybe<Array<Maybe<IRoleRuleResponse>>>;
};

export type IGetManyTagsRequest = {
  filter?: InputMaybe<IGetManyTagsRequestFilterInput>;
  paginationArguments?: InputMaybe<IPaginationArgumentsInput>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetManyTagsRequestFilterInput = {
  referenceId?: InputMaybe<Scalars['String']>;
  referenceType?: InputMaybe<IReferenceType>;
};

export type IGetManyTagsResponse = {
  __typename: 'GetManyTagsResponse';
  connection?: Maybe<ITagConnection>;
};

export type IGetManyUserRolesRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetManyUserRolesResponse = {
  __typename: 'GetManyUserRolesResponse';
  userRoles?: Maybe<Array<Maybe<IUserRoleResponse>>>;
};

export type IGetManyUsersProfileRequestInput = {
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type IGetManyUsersProfileResponse = {
  __typename: 'GetManyUsersProfileResponse';
  profiles?: Maybe<Array<Maybe<ICaisyUserProfile>>>;
};

export type IGetManyViewPinsRequestInput = {
  filter?: InputMaybe<IGetManyViewsFilterInput>;
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetManyViewPinsResponse = {
  __typename: 'GetManyViewPinsResponse';
  views?: Maybe<Array<Maybe<IViewResponse>>>;
};

export type IGetManyViewsFilterInput = {
  privat?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<IViewTarget>;
};

export type IGetManyViewsRequestInput = {
  filter?: InputMaybe<IGetManyViewsFilterInput>;
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetManyViewsResponse = {
  __typename: 'GetManyViewsResponse';
  views?: Maybe<Array<Maybe<IViewResponse>>>;
};

export type IGetManyWebhookCallsRequestInput = {
  filter?: InputMaybe<IWebhookCallsFilterInput>;
  paginationArguments?: InputMaybe<IPaginationArgumentsInput>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetManyWebhookCallsResponse = {
  __typename: 'GetManyWebhookCallsResponse';
  connection?: Maybe<IWebhookCallConnection>;
};

export type IGetOrganizationByIdRequestInput = {
  organizationId?: InputMaybe<Scalars['String']>;
};

export type IGetOrganizationByIdResponse = {
  __typename: 'GetOrganizationByIDResponse';
  organization?: Maybe<IOrganizationResponse>;
};

export type IGetOrganizationMembersRequestInput = {
  includeGuests?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['String']>;
  paginationArguments?: InputMaybe<IPaginationArgumentsForwardInput>;
};

export type IGetOrganizationMembersResponse = {
  __typename: 'GetOrganizationMembersResponse';
  connection?: Maybe<IMemberConnection>;
};

export type IGetOrganizationMembersWithAggregationRequestInput = {
  organizationId?: InputMaybe<Scalars['String']>;
  paginationArguments?: InputMaybe<IPaginationArgumentsForwardInput>;
};

export type IGetOrganizationMembersWithAggregationResponse = {
  __typename: 'GetOrganizationMembersWithAggregationResponse';
  connection?: Maybe<IOrganizationMembersWithAggregationConnection>;
};

export type IGetOrganizationQuotaPlanRequestInput = {
  organizationId?: InputMaybe<Scalars['String']>;
};

export type IGetOrganizationQuotaPlanResponse = {
  __typename: 'GetOrganizationQuotaPlanResponse';
  organizationId?: Maybe<Scalars['String']>;
  quotaPlan?: Maybe<IOrganizationQuotaPlan>;
};

export type IGetPaymentInfoRequestInput = {
  organizationId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetPaymentInfoResponse = {
  __typename: 'GetPaymentInfoResponse';
  createdAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  last4CardDigits?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type IGetPaymentStatusInput = {
  organizationId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetPaymentStatusResponse = {
  __typename: 'GetPaymentStatusResponse';
  valid?: Maybe<Scalars['Boolean']>;
};

export type IGetPermissionGrantRequestInput = {
  action?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  object?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  target?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetPermissionGrantResponse = {
  __typename: 'GetPermissionGrantResponse';
  appliedRole?: Maybe<IRole>;
  success?: Maybe<Scalars['Boolean']>;
};

export type IGetPermissionSetRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetPermissionSetResponse = {
  __typename: 'GetPermissionSetResponse';
  appliedRole?: Maybe<IRole>;
  permissionSet?: Maybe<Array<Maybe<IPermissionSet>>>;
};

export type IGetPinnedProjectsRequestInput = {
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetPinnedProjectsResponse = {
  __typename: 'GetPinnedProjectsResponse';
  projectIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IGetPreviewByIdRequestInput = {
  previewId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetPreviewByIdResponse = {
  __typename: 'GetPreviewByIdResponse';
  preview?: Maybe<IPreviewWithItemsResponse>;
};

export type IGetPreviewItemsByBlueprintIdRequestInput = {
  blueprintId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetPreviewItemsByBlueprintIdResponse = {
  __typename: 'GetPreviewItemsByBlueprintIdResponse';
  previewItems?: Maybe<Array<Maybe<IPreviewItemResponse>>>;
};

export type IGetProjectBillingStatusRequestInput = {
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetProjectBillingStatusResponse = {
  __typename: 'GetProjectBillingStatusResponse';
  billingPlanName?: Maybe<Scalars['String']>;
  cancelAt?: Maybe<Scalars['String']>;
  endMonth?: Maybe<Scalars['String']>;
  nextPlan?: Maybe<Scalars['String']>;
};

export type IGetProjectByIdRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetProjectByIdResponse = {
  __typename: 'GetProjectByIDResponse';
  project?: Maybe<IProjectResponse>;
};

export type IGetProjectByIdWithInheritanceRequestInput = {
  projectId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetProjectByIdWithInheritanceResponse = {
  __typename: 'GetProjectByIDWithInheritanceResponse';
  group?: Maybe<IGroupResponse>;
  organization?: Maybe<IOrganizationResponse>;
  project?: Maybe<IProjectResponse>;
};

export type IGetProjectMembersRequestInput = {
  paginationArguments?: InputMaybe<IPaginationArgumentsInput>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetProjectMembersResponse = {
  __typename: 'GetProjectMembersResponse';
  connection?: Maybe<IMemberConnection>;
};

export type IGetProjectMembersWithAggregationRequestInput = {
  paginationArguments?: InputMaybe<IPaginationArgumentsForwardInput>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetProjectMembersWithAggregationResponse = {
  __typename: 'GetProjectMembersWithAggregationResponse';
  connection?: Maybe<IProjectMembersWithAggregationConnection>;
};

export type IGetProjectOnboardingRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetProjectOnboardingResponse = {
  __typename: 'GetProjectOnboardingResponse';
  onboarding?: Maybe<IProjectOnboarding>;
};

export type IGetProjectPortRequestInput = {
  portId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetProjectPortResponse = {
  __typename: 'GetProjectPortResponse';
  createdAt?: Maybe<Scalars['Time']>;
  expiresAt?: Maybe<Scalars['Time']>;
  finished?: Maybe<Scalars['Boolean']>;
  portErrors?: Maybe<Array<Maybe<IPortError>>>;
  portType?: Maybe<IPortType>;
  projectId?: Maybe<Scalars['String']>;
  selectionProgress?: Maybe<IProjectPortSelectionProgress>;
};

export type IGetProjectQuotaPlanRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetProjectQuotaPlanResponse = {
  __typename: 'GetProjectQuotaPlanResponse';
  projectId?: Maybe<Scalars['String']>;
  quotaPlan?: Maybe<IProjectQuotaPlan>;
};

export type IGetProjectSettingsRequestInput = {
  projectId: Scalars['String'];
};

export type IGetProjectSettingsResponse = {
  __typename: 'GetProjectSettingsResponse';
  settings?: Maybe<IProjectSettings>;
};

export type IGetProjectTemplateSettingsRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetProjectTemplateSettingsResponse = {
  __typename: 'GetProjectTemplateSettingsResponse';
  details?: Maybe<ITemplateDetails>;
  projectId?: Maybe<Scalars['String']>;
  publicTemplate?: Maybe<Scalars['Boolean']>;
  selection?: Maybe<IProjectTemplatePortSelection>;
  templateId?: Maybe<Scalars['String']>;
};

export type IGetProjectTemplateUsageRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetProjectTemplateUsageResponse = {
  __typename: 'GetProjectTemplateUsageResponse';
  templates?: Maybe<Array<Maybe<ITemplateUsageResponse>>>;
};

export type IGetRecentActiveProjectsRequestInput = {
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetRecentActiveProjectsResponse = {
  __typename: 'GetRecentActiveProjectsResponse';
  projectIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IGetReleaseByIdRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
  releaseId?: InputMaybe<Scalars['String']>;
};

export type IGetReleaseByIdResponse = {
  __typename: 'GetReleaseByIdResponse';
  release?: Maybe<IReleaseResponse>;
};

export type IGetRoleRuleByIdRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  ruleId?: InputMaybe<Scalars['String']>;
};

export type IGetRoleRuleByIdResponse = {
  __typename: 'GetRoleRuleByIDResponse';
  roleRule?: Maybe<IRoleRuleResponse>;
};

export type IGetUnpublishedLinkedDocumentsRequestInput = {
  blueprintBranch?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetUnpublishedLinkedDocumentsResponse = {
  __typename: 'GetUnpublishedLinkedDocumentsResponse';
  document?: Maybe<Array<Maybe<IDocument>>>;
};

export type IGetUsageOfBlueprintFieldRequestInput = {
  blueprintBranch?: InputMaybe<Scalars['String']>;
  blueprintFieldId?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IGetUsageOfBlueprintFieldResponse = {
  __typename: 'GetUsageOfBlueprintFieldResponse';
  usageAmount?: Maybe<Scalars['Int']>;
};

export type IGetUserInformationRequestInput = {
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetUserInformationResponse = {
  __typename: 'GetUserInformationResponse';
  marketingProfile?: Maybe<IUserMarketingProfile>;
  pinnedProjects?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferredUILanguage?: Maybe<Scalars['String']>;
  preferredUITheme?: Maybe<Scalars['Int']>;
  recentActiveProjects?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IGetUserOnboardingRequestInput = {
  userId?: InputMaybe<Scalars['String']>;
};

export type IGetUserOnboardingResponse = {
  __typename: 'GetUserOnboardingResponse';
  onboarding?: Maybe<IUserOnboarding>;
};

export type IGetUserResponse = {
  __typename: 'GetUserResponse';
  profile?: Maybe<ICaisyUserProfile>;
};

export type IGetUserRoleWithRoleRulesRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['String']>;
};

export type IGetUserRoleWithRoleRulesResponse = {
  __typename: 'GetUserRoleWithRoleRulesResponse';
  description?: Maybe<Scalars['String']>;
  inheritedBySystemRole?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
  roleRules?: Maybe<Array<Maybe<IRoleRuleResponse>>>;
  title?: Maybe<Scalars['String']>;
};

export type IGetWebhookCallRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
  webhookCallId?: InputMaybe<Scalars['String']>;
};

export type IGetWebhookCallResponse = {
  __typename: 'GetWebhookCallResponse';
  webhookCall?: Maybe<IWebhookCallResponse>;
};

export type IGroupInputInput = {
  alias?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  logoAssetUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type IGroupMembersWithAggregation = {
  __typename: 'GroupMembersWithAggregation';
  membersResponse?: Maybe<IAggregatedMemberResponse>;
  projectsCount?: Maybe<Scalars['Int']>;
  relation?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type IGroupMembersWithAggregationConnection = {
  __typename: 'GroupMembersWithAggregationConnection';
  edges?: Maybe<Array<Maybe<IGroupMembersWithAggregationConnectionEdge>>>;
  pageInfo?: Maybe<IPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IGroupMembersWithAggregationConnectionEdge = {
  __typename: 'GroupMembersWithAggregationConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<IGroupMembersWithAggregation>;
};

export type IGroupResponse = {
  __typename: 'GroupResponse';
  alias?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  logoAssetUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  roleByUser?: Maybe<IAnyToRoleConnection>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type IGroupResponseConnection = {
  __typename: 'GroupResponseConnection';
  edges?: Maybe<Array<Maybe<IGroupResponseConnectionEdge>>>;
  pageInfo?: Maybe<IPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IGroupResponseConnectionEdge = {
  __typename: 'GroupResponseConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<IGroupResponse>;
};

export enum IHttpMethod {
  Delete = 'DELETE',
  Get = 'GET',
  Head = 'HEAD',
  Patch = 'PATCH',
  Post = 'POST',
  Put = 'PUT'
}

export type IImportFromTemplateRequestInput = {
  documentationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  selection?: InputMaybe<IProjectTemplatePortSelectionInput>;
  source?: InputMaybe<IImportFromTemplateSourceInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IImportFromTemplateResponse = {
  __typename: 'ImportFromTemplateResponse';
  portId?: Maybe<Scalars['String']>;
};

export type IImportFromTemplateSourceInput = {
  templateId?: InputMaybe<Scalars['String']>;
};

export type IInvitations = {
  __typename: 'Invitations';
  invitedBy?: Maybe<IUserProfile>;
  organizationId?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
};

export type ILinkManyReleaseDocumentRequestInput = {
  documentId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  projectId?: InputMaybe<Scalars['String']>;
  releaseId?: InputMaybe<Scalars['String']>;
  replaceExistingDocuments?: InputMaybe<Scalars['Boolean']>;
};

export type ILinkManyReleaseDocumentResponse = {
  __typename: 'LinkManyReleaseDocumentResponse';
  successful?: Maybe<Scalars['Boolean']>;
};

export type ILoginResult = {
  __typename: 'LoginResult';
  sessionId?: Maybe<Scalars['String']>;
  tokens?: Maybe<ITokens>;
  user?: Maybe<ILoginResultUser>;
};

export type ILoginResultUser = {
  __typename: 'LoginResultUser';
  email: Scalars['String'];
  id: Scalars['ID'];
  username?: Maybe<Scalars['String']>;
};

export type IMarkAllNotificationsAsReadRequestInput = {
  userId?: InputMaybe<Scalars['String']>;
};

export type IMarkAllNotificationsAsReadResponse = {
  __typename: 'MarkAllNotificationsAsReadResponse';
  totalCount: Scalars['Int'];
};

export type IMarkNotificationAsReadRequestInput = {
  notificationId: Scalars['String'];
  read?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IMarkNotificationAsReadResponse = {
  __typename: 'MarkNotificationAsReadResponse';
  notification: INotificationResponse;
};

export type IMemberConnection = {
  __typename: 'MemberConnection';
  edges?: Maybe<Array<Maybe<IMemberConnectionEdge>>>;
  pageInfo?: Maybe<IPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IMemberConnectionEdge = {
  __typename: 'MemberConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<IMemberResponse>;
};

export type IMemberResponse = {
  __typename: 'MemberResponse';
  memberStatus?: Maybe<Scalars['String']>;
  role?: Maybe<IRole>;
  user?: Maybe<IUserProfile>;
};

export type IMoveProjectRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
  targetGroupId?: InputMaybe<Scalars['String']>;
  targetOrganizationId?: InputMaybe<Scalars['String']>;
};

export type IMoveProjectResponse = {
  __typename: 'MoveProjectResponse';
  project?: Maybe<IProjectResponse>;
};

export type IMutation = {
  __typename: 'Mutation';
  ActivateMembership?: Maybe<IActivateMembershipResponse>;
  ActivateMembershipFromInvitationCode?: Maybe<IActivateMembershipFromInvitationCodeResponse>;
  AssignManyRoles?: Maybe<IAssignManyRolesResponse>;
  AssignRole?: Maybe<IAssignRoleResponse>;
  CheckBlueprintNameAvailable?: Maybe<ICheckBlueprintNameAvailableResponse>;
  CopyMemberFromProjectToProject?: Maybe<ICopyMemberFromProjectToProjectResponse>;
  CreateApiKey?: Maybe<ICreateApiKeyResponse>;
  CreateBlueprint?: Maybe<ICreateBlueprintResponse>;
  CreateCheckoutSession?: Maybe<ICreateCheckoutSessionResponse>;
  CreateComment?: Maybe<ICreateCommentResponse>;
  CreateDocument?: Maybe<ICreateDocumentResponse>;
  CreateDocumentFieldLocale?: Maybe<ICreateDocumentFieldLocaleResponse>;
  CreateGroup?: Maybe<ICreateGroupResponse>;
  CreateManyViews?: Maybe<ICreateManyViewsResponse>;
  CreateOrganization?: Maybe<ICreateOrganizationResponse>;
  CreatePortalSession?: Maybe<ICreatePortalSessionResponse>;
  CreatePreview?: Maybe<ICreatePreviewResponse>;
  CreatePreviewItem?: Maybe<ICreatePreviewItemResponse>;
  CreateProject?: Maybe<ICreateProjectResponse>;
  CreateRelease?: Maybe<ICreateReleaseResponse>;
  CreateRoleRule?: Maybe<ICreateRoleRuleResponse>;
  CreateTag?: Maybe<ICreateTagResponse>;
  CreateUserAccessToken?: Maybe<ICreateUserAccessTokenResponse>;
  CreateUserRole?: Maybe<ICreateUserRoleResponse>;
  CreateView?: Maybe<ICreateViewResponse>;
  CreateViewPin?: Maybe<ICreateViewPinResponse>;
  CreateWebhook?: Maybe<ICreateWebhookResponse>;
  DeActivateOrganizationMember?: Maybe<IDeActivateOrganizationMemberResponse>;
  DeAssignManyRoles?: Maybe<IDeAssignManyRolesResponse>;
  DeAssignRole?: Maybe<IDeAssignRoleResponse>;
  DeleteApiKey?: Maybe<IDeleteApiKeyResponse>;
  DeleteBlueprint?: Maybe<IDeleteBlueprintResponse>;
  DeleteComment?: Maybe<IDeleteCommentResponse>;
  DeleteDocument?: Maybe<IDeleteDocumentResponse>;
  DeleteDocumentFieldLocale?: Maybe<IDeleteDocumentFieldLocaleResponse>;
  DeleteEntrypoint?: Maybe<IDeleteEntrypointResponse>;
  DeleteGroup?: Maybe<IDeleteGroupResponse>;
  DeleteManyDocuments?: Maybe<IDeleteManyDocumentsResponse>;
  DeleteManyReleases?: Maybe<IDeleteManyReleasesResponse>;
  DeleteOrganization?: Maybe<IDeleteOrganizationResponse>;
  DeletePreview?: Maybe<IDeletePreviewResponse>;
  DeletePreviewItem?: Maybe<IDeletePreviewItemResponse>;
  DeleteProject?: Maybe<IDeleteProjectResponse>;
  DeleteRoleRule?: Maybe<IDeleteRoleRuleResponse>;
  DeleteTag?: Maybe<IDeleteTagResponse>;
  DeleteUserAccessToken?: Maybe<IDeleteUserAccessTokenResponse>;
  DeleteUserFromGroupCascading?: Maybe<IDeleteUserFromGroupCascadingResponse>;
  DeleteUserFromOrganization?: Maybe<IDeleteUserFromOrganizationResponse>;
  DeleteUserRole?: Maybe<IDeleteUserRoleResponse>;
  DeleteView?: Maybe<IDeleteViewResponse>;
  DeleteViewPin?: Maybe<IDeleteViewPinResponse>;
  DeleteWebhook?: Maybe<IDeleteWebhookResponse>;
  DuplicateDocument?: Maybe<IDuplicateDocumentResponse>;
  DuplicateToProject?: Maybe<IDuplicateToProjectResponse>;
  ImportFromTemplate?: Maybe<IImportFromTemplateResponse>;
  LinkManyReleaseDocument?: Maybe<ILinkManyReleaseDocumentResponse>;
  MarkAllNotificationsAsRead?: Maybe<IMarkAllNotificationsAsReadResponse>;
  MarkNotificationAsRead?: Maybe<IMarkNotificationAsReadResponse>;
  MoveProject?: Maybe<IMoveProjectResponse>;
  PutManyBlueprints?: Maybe<IPutManyBlueprintsResponse>;
  PutManyDocumentFieldLocales?: Maybe<IPutManyDocumentFieldLocalesResponse>;
  PutManyDocuments?: Maybe<IPutManyDocumentsResponse>;
  PutManyPreviews?: Maybe<IPutManyPreviewsResponse>;
  PutManyTags?: Maybe<IPutManyTagsResponse>;
  PutManyViews?: Maybe<IPutManyViewsResponse>;
  PutManyWebhooks?: Maybe<IPutManyWebhooksResponse>;
  ReActivateOrganizationMember?: Maybe<IReActivateOrganizationMemberResponse>;
  ResendOrganizationInvitation?: Maybe<IResendOrganizationInvitationResponse>;
  ResetProjectOnboarding?: Maybe<IResetProjectOnboardingResponse>;
  ResetUserOnboarding?: Maybe<IResetUserOnboardingResponse>;
  SetCommentPriority?: Maybe<ISetCommentPriorityResponse>;
  SetCommentStatus?: Maybe<ISetCommentStatusResponse>;
  SetNotificationPreferences?: Maybe<ISetNotificationPreferencesResponse>;
  SetPinnedProjects?: Maybe<ISetPinnedProjectsResponse>;
  SetPreferredUILanguage?: Maybe<ISetPreferredUiLanguageResponse>;
  SetPreferredUITheme?: Maybe<ISetPreferredUiThemeResponse>;
  SetProjectBillingPlan?: Maybe<ISetProjectBillingPlanResponse>;
  SetProjectOnboardingCompleted?: Maybe<ISetProjectOnboardingCompletedResponse>;
  SetProjectOnboardingEventCompleted?: Maybe<ISetProjectOnboardingEventCompletedResponse>;
  SetProjectSettings?: Maybe<ISetProjectSettingsResponse>;
  SetProjectTemplateSettings?: Maybe<ISetProjectTemplateSettingsResponse>;
  SetUserActiveProject?: Maybe<ISetUserActiveProjectResponse>;
  SetUserMarketingProfile?: Maybe<ISetUserMarketingProfileResponse>;
  SetUserOnboardingCompleted?: Maybe<ISetUserOnboardingCompletedResponse>;
  SetUserOnboardingEventCompleted?: Maybe<ISetUserOnboardingEventCompletedResponse>;
  SetupPasswordWithActionCode?: Maybe<ISetupPasswordWithActionCodeResponse>;
  UnlinkManyReleaseDocument?: Maybe<IUnlinkManyReleaseDocumentResponse>;
  UpdateBlueprint?: Maybe<IUpdateBlueprintResponse>;
  UpdateComment?: Maybe<IUpdateCommentResponse>;
  UpdateDocument?: Maybe<IUpdateDocumentResponse>;
  UpdateDocumentField?: Maybe<IUpdateDocumentFieldResponse>;
  UpdateDocumentFieldLocale?: Maybe<IUpdateDocumentFieldLocaleResponse>;
  UpdateEntrypoint?: Maybe<IUpdateEntrypointResponse>;
  UpdateGroup?: Maybe<IUpdateGroupResponse>;
  UpdateManyDocuments?: Maybe<IUpdateManyDocumentsResponse>;
  UpdateOrganization?: Maybe<IUpdateOrganizationResponse>;
  UpdatePreview?: Maybe<IUpdatePreviewResponse>;
  UpdatePreviewItem?: Maybe<IUpdatePreviewItemResponse>;
  UpdateProject?: Maybe<IUpdateProjectResponse>;
  UpdateRelease?: Maybe<IUpdateReleaseResponse>;
  UpdateRoleRule?: Maybe<IUpdateRoleRuleResponse>;
  UpdateTag?: Maybe<IUpdateTagResponse>;
  UpdateUserProfileRequest?: Maybe<IUpdateUserProfileResponse>;
  UpdateUserRole?: Maybe<IUpdateUserRoleResponse>;
  UpdateView?: Maybe<IUpdateViewResponse>;
  UpdateViewPin?: Maybe<IUpdateViewPinResponse>;
  UpdateWebhook?: Maybe<IUpdateWebhookResponse>;
  ValidateDocument?: Maybe<IValidateDocumentResponse>;
  ValidateDocumentField?: Maybe<IValidateDocumentFieldResponse>;
  ValidateManyDocuments?: Maybe<IValidateManyDocumentsResponse>;
  ValidateRelease?: Maybe<IValidateReleaseResponse>;
  VerifyActionCode?: Maybe<IVerifyActionCodeResponse>;
  createCaisyUser?: Maybe<ICreateCaisyUserResult>;
  logout?: Maybe<Scalars['Boolean']>;
  sendResetPasswordEmail?: Maybe<Scalars['Boolean']>;
};


export type IMutationActivateMembershipArgs = {
  input?: InputMaybe<IActivateMembershipRequestInput>;
};


export type IMutationActivateMembershipFromInvitationCodeArgs = {
  input?: InputMaybe<IActivateMembershipFromInvitationCodeRequestInput>;
};


export type IMutationAssignManyRolesArgs = {
  input?: InputMaybe<IAssignManyRolesRequestInput>;
};


export type IMutationAssignRoleArgs = {
  input?: InputMaybe<IAssignRoleRequestInput>;
};


export type IMutationCheckBlueprintNameAvailableArgs = {
  input?: InputMaybe<ICheckBlueprintNameAvailableRequest>;
};


export type IMutationCopyMemberFromProjectToProjectArgs = {
  input?: InputMaybe<ICopyMemberFromProjectToProjectRequestInput>;
};


export type IMutationCreateApiKeyArgs = {
  input?: InputMaybe<ICreateApiKeyRequestInput>;
};


export type IMutationCreateBlueprintArgs = {
  input?: InputMaybe<ICreateBlueprintRequest>;
};


export type IMutationCreateCheckoutSessionArgs = {
  input?: InputMaybe<ICreateCheckoutSessionRequestInput>;
};


export type IMutationCreateCommentArgs = {
  input?: InputMaybe<ICreateCommentRequestInput>;
};


export type IMutationCreateDocumentArgs = {
  input?: InputMaybe<ICreateDocumentRequestInput>;
};


export type IMutationCreateDocumentFieldLocaleArgs = {
  input?: InputMaybe<ICreateDocumentFieldLocaleRequestInput>;
};


export type IMutationCreateGroupArgs = {
  input?: InputMaybe<ICreateGroupRequestInput>;
};


export type IMutationCreateManyViewsArgs = {
  input?: InputMaybe<ICreateManyViewsRequestInput>;
};


export type IMutationCreateOrganizationArgs = {
  input?: InputMaybe<ICreateOrganizationRequestInput>;
};


export type IMutationCreatePortalSessionArgs = {
  input?: InputMaybe<ICreatePortalSessionRequestInput>;
};


export type IMutationCreatePreviewArgs = {
  input?: InputMaybe<ICreatePreviewRequestInput>;
};


export type IMutationCreatePreviewItemArgs = {
  input?: InputMaybe<ICreatePreviewItemRequestInput>;
};


export type IMutationCreateProjectArgs = {
  input?: InputMaybe<ICreateProjectRequestInput>;
};


export type IMutationCreateReleaseArgs = {
  input?: InputMaybe<ICreateReleaseRequestInput>;
};


export type IMutationCreateRoleRuleArgs = {
  input?: InputMaybe<ICreateRoleRuleRequestInput>;
};


export type IMutationCreateTagArgs = {
  input?: InputMaybe<ICreateTagRequest>;
};


export type IMutationCreateUserAccessTokenArgs = {
  input?: InputMaybe<ICreateUserAccessTokenRequestInput>;
};


export type IMutationCreateUserRoleArgs = {
  input?: InputMaybe<ICreateUserRoleRequestInput>;
};


export type IMutationCreateViewArgs = {
  input?: InputMaybe<ICreateViewRequestInput>;
};


export type IMutationCreateViewPinArgs = {
  input?: InputMaybe<ICreateViewPinRequestInput>;
};


export type IMutationCreateWebhookArgs = {
  input?: InputMaybe<ICreateWebhookRequestInput>;
};


export type IMutationDeActivateOrganizationMemberArgs = {
  input?: InputMaybe<IDeActivateOrganizationMemberRequestInput>;
};


export type IMutationDeAssignManyRolesArgs = {
  input?: InputMaybe<IDeAssignManyRolesRequestInput>;
};


export type IMutationDeAssignRoleArgs = {
  input?: InputMaybe<IDeAssignRoleRequestInput>;
};


export type IMutationDeleteApiKeyArgs = {
  input?: InputMaybe<IDeleteApiKeyRequestInput>;
};


export type IMutationDeleteBlueprintArgs = {
  input?: InputMaybe<IDeleteBlueprintRequest>;
};


export type IMutationDeleteCommentArgs = {
  input?: InputMaybe<IDeleteCommentRequestInput>;
};


export type IMutationDeleteDocumentArgs = {
  input?: InputMaybe<IDeleteDocumentRequestInput>;
};


export type IMutationDeleteDocumentFieldLocaleArgs = {
  input?: InputMaybe<IDeleteDocumentFieldLocaleRequestInput>;
};


export type IMutationDeleteEntrypointArgs = {
  input?: InputMaybe<IDeleteEntrypointRequestInput>;
};


export type IMutationDeleteGroupArgs = {
  input?: InputMaybe<IDeleteGroupRequestInput>;
};


export type IMutationDeleteManyDocumentsArgs = {
  input?: InputMaybe<IDeleteManyDocumentsRequestInput>;
};


export type IMutationDeleteManyReleasesArgs = {
  input?: InputMaybe<IDeleteManyReleasesRequestInput>;
};


export type IMutationDeleteOrganizationArgs = {
  input?: InputMaybe<IDeleteOrganizationRequestInput>;
};


export type IMutationDeletePreviewArgs = {
  input?: InputMaybe<IDeletePreviewRequestInput>;
};


export type IMutationDeletePreviewItemArgs = {
  input?: InputMaybe<IDeletePreviewItemRequestInput>;
};


export type IMutationDeleteProjectArgs = {
  input?: InputMaybe<IDeleteProjectRequestInput>;
};


export type IMutationDeleteRoleRuleArgs = {
  input?: InputMaybe<IDeleteRoleRuleRequestInput>;
};


export type IMutationDeleteTagArgs = {
  input?: InputMaybe<IDeleteTagRequest>;
};


export type IMutationDeleteUserAccessTokenArgs = {
  input?: InputMaybe<IDeleteUserAccessTokenRequestInput>;
};


export type IMutationDeleteUserFromGroupCascadingArgs = {
  input?: InputMaybe<IDeleteUserFromGroupCascadingRequestInput>;
};


export type IMutationDeleteUserFromOrganizationArgs = {
  input?: InputMaybe<IDeleteUserFromOrganizationRequestInput>;
};


export type IMutationDeleteUserRoleArgs = {
  input?: InputMaybe<IDeleteUserRoleRequestInput>;
};


export type IMutationDeleteViewArgs = {
  input?: InputMaybe<IDeleteViewRequestInput>;
};


export type IMutationDeleteViewPinArgs = {
  input?: InputMaybe<IDeleteViewPinRequestInput>;
};


export type IMutationDeleteWebhookArgs = {
  input?: InputMaybe<IDeleteWebhookRequestInput>;
};


export type IMutationDuplicateDocumentArgs = {
  input?: InputMaybe<IDuplicateDocumentRequestInput>;
};


export type IMutationDuplicateToProjectArgs = {
  input?: InputMaybe<IDuplicateToProjectRequestInput>;
};


export type IMutationImportFromTemplateArgs = {
  input?: InputMaybe<IImportFromTemplateRequestInput>;
};


export type IMutationLinkManyReleaseDocumentArgs = {
  input?: InputMaybe<ILinkManyReleaseDocumentRequestInput>;
};


export type IMutationMarkAllNotificationsAsReadArgs = {
  input?: InputMaybe<IMarkAllNotificationsAsReadRequestInput>;
};


export type IMutationMarkNotificationAsReadArgs = {
  input?: InputMaybe<IMarkNotificationAsReadRequestInput>;
};


export type IMutationMoveProjectArgs = {
  input?: InputMaybe<IMoveProjectRequestInput>;
};


export type IMutationPutManyBlueprintsArgs = {
  input?: InputMaybe<IPutManyBlueprintsRequestInput>;
};


export type IMutationPutManyDocumentFieldLocalesArgs = {
  input?: InputMaybe<IPutManyDocumentFieldLocalesRequestInput>;
};


export type IMutationPutManyDocumentsArgs = {
  input?: InputMaybe<IPutManyDocumentsRequestInput>;
};


export type IMutationPutManyPreviewsArgs = {
  input?: InputMaybe<IPutManyPreviewsRequestInput>;
};


export type IMutationPutManyTagsArgs = {
  input?: InputMaybe<IPutManyTagsRequestInput>;
};


export type IMutationPutManyViewsArgs = {
  input?: InputMaybe<IPutManyViewsRequestInput>;
};


export type IMutationPutManyWebhooksArgs = {
  input?: InputMaybe<IPutManyWebhooksRequestInput>;
};


export type IMutationReActivateOrganizationMemberArgs = {
  input?: InputMaybe<IReActivateOrganizationMemberRequestInput>;
};


export type IMutationResendOrganizationInvitationArgs = {
  input?: InputMaybe<IResendOrganizationInvitationRequestInput>;
};


export type IMutationResetProjectOnboardingArgs = {
  input?: InputMaybe<IResetProjectOnboardingRequestInput>;
};


export type IMutationResetUserOnboardingArgs = {
  input?: InputMaybe<IResetUserOnboardingRequestInput>;
};


export type IMutationSetCommentPriorityArgs = {
  input?: InputMaybe<ISetCommentPriorityRequestInput>;
};


export type IMutationSetCommentStatusArgs = {
  input?: InputMaybe<ISetCommentStatusRequestInput>;
};


export type IMutationSetNotificationPreferencesArgs = {
  input?: InputMaybe<ISetNotificationPreferencesRequestInput>;
};


export type IMutationSetPinnedProjectsArgs = {
  input?: InputMaybe<ISetPinnedProjectsRequestInput>;
};


export type IMutationSetPreferredUiLanguageArgs = {
  input: ISetPreferredUiLanguageRequest;
};


export type IMutationSetPreferredUiThemeArgs = {
  input: ISetPreferredUiThemeRequest;
};


export type IMutationSetProjectBillingPlanArgs = {
  input?: InputMaybe<ISetProjectBillingPlanRequestInput>;
};


export type IMutationSetProjectOnboardingCompletedArgs = {
  input?: InputMaybe<ISetProjectOnboardingCompletedRequestInput>;
};


export type IMutationSetProjectOnboardingEventCompletedArgs = {
  input?: InputMaybe<ISetProjectOnboardingEventCompletedRequestInput>;
};


export type IMutationSetProjectSettingsArgs = {
  input?: InputMaybe<ISetProjectSettingsRequestInput>;
};


export type IMutationSetProjectTemplateSettingsArgs = {
  input?: InputMaybe<ISetProjectTemplateSettingsRequestInput>;
};


export type IMutationSetUserActiveProjectArgs = {
  input?: InputMaybe<ISetUserActiveProjectRequestInput>;
};


export type IMutationSetUserMarketingProfileArgs = {
  input?: InputMaybe<ISetUserMarketingProfileRequestInput>;
};


export type IMutationSetUserOnboardingCompletedArgs = {
  input?: InputMaybe<ISetUserOnboardingCompletedRequestInput>;
};


export type IMutationSetUserOnboardingEventCompletedArgs = {
  input?: InputMaybe<ISetUserOnboardingEventCompletedRequestInput>;
};


export type IMutationSetupPasswordWithActionCodeArgs = {
  input?: InputMaybe<ISetupPasswordWithActionCodeRequestInput>;
};


export type IMutationUnlinkManyReleaseDocumentArgs = {
  input?: InputMaybe<IUnlinkManyReleaseDocumentRequestInput>;
};


export type IMutationUpdateBlueprintArgs = {
  input?: InputMaybe<IUpdateBlueprintRequest>;
};


export type IMutationUpdateCommentArgs = {
  input?: InputMaybe<IUpdateCommentRequestInput>;
};


export type IMutationUpdateDocumentArgs = {
  input?: InputMaybe<IUpdateDocumentRequestInput>;
};


export type IMutationUpdateDocumentFieldArgs = {
  input?: InputMaybe<IUpdateDocumentFieldRequestInput>;
};


export type IMutationUpdateDocumentFieldLocaleArgs = {
  input?: InputMaybe<IUpdateDocumentFieldLocaleRequestInput>;
};


export type IMutationUpdateEntrypointArgs = {
  input?: InputMaybe<IUpdateEntrypointRequestInput>;
};


export type IMutationUpdateGroupArgs = {
  input?: InputMaybe<IUpdateGroupRequestInput>;
};


export type IMutationUpdateManyDocumentsArgs = {
  input?: InputMaybe<IUpdateManyDocumentRequestInput>;
};


export type IMutationUpdateOrganizationArgs = {
  input?: InputMaybe<IUpdateOrganizationRequestInput>;
};


export type IMutationUpdatePreviewArgs = {
  input?: InputMaybe<IUpdatePreviewRequestInput>;
};


export type IMutationUpdatePreviewItemArgs = {
  input?: InputMaybe<IUpdatePreviewItemRequestInput>;
};


export type IMutationUpdateProjectArgs = {
  input?: InputMaybe<IUpdateProjectRequestInput>;
};


export type IMutationUpdateReleaseArgs = {
  input?: InputMaybe<IUpdateReleaseRequestInput>;
};


export type IMutationUpdateRoleRuleArgs = {
  input?: InputMaybe<IUpdateRoleRuleRequestInput>;
};


export type IMutationUpdateTagArgs = {
  input?: InputMaybe<IUpdateTagRequest>;
};


export type IMutationUpdateUserProfileRequestArgs = {
  input: IUpdateUserProfileRequestInput;
};


export type IMutationUpdateUserRoleArgs = {
  input?: InputMaybe<IUpdateUserRoleRequestInput>;
};


export type IMutationUpdateViewArgs = {
  input?: InputMaybe<IUpdateViewRequestInput>;
};


export type IMutationUpdateViewPinArgs = {
  input?: InputMaybe<IUpdateViewPinRequestInput>;
};


export type IMutationUpdateWebhookArgs = {
  input?: InputMaybe<IUpdateWebhookRequestInput>;
};


export type IMutationValidateDocumentArgs = {
  input?: InputMaybe<IValidateDocumentRequestInput>;
};


export type IMutationValidateDocumentFieldArgs = {
  input?: InputMaybe<IValidateDocumentFieldRequestInput>;
};


export type IMutationValidateManyDocumentsArgs = {
  input?: InputMaybe<IValidateManyDocumentsRequestInput>;
};


export type IMutationValidateReleaseArgs = {
  input?: InputMaybe<IValidateReleaseRequestInput>;
};


export type IMutationVerifyActionCodeArgs = {
  input?: InputMaybe<IVerifyActionCodeRequestInput>;
};


export type IMutationCreateCaisyUserArgs = {
  email: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<IUserProfileInput>;
  provider?: InputMaybe<Scalars['String']>;
  providerUserId?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};


export type IMutationSendResetPasswordEmailArgs = {
  email: Scalars['String'];
  mode?: InputMaybe<Scalars['String']>;
};

export enum INotificationCategory {
  NotificationCategoryComment = 'NOTIFICATION_CATEGORY_COMMENT',
  NotificationCategoryInvite = 'NOTIFICATION_CATEGORY_INVITE'
}

export type INotificationInput = {
  category: INotificationCategory;
  forUserId: Scalars['String'];
  key: INotificationKey;
  meta?: InputMaybe<INotificationMetaInput>;
  projectId?: InputMaybe<Scalars['String']>;
  read: Scalars['Boolean'];
};

export enum INotificationKey {
  CommentChangedDone = 'COMMENT_CHANGED_DONE',
  CommentChangedPriorty = 'COMMENT_CHANGED_PRIORTY',
  CommentMentioned = 'COMMENT_MENTIONED',
  CommentReplyed = 'COMMENT_REPLYED',
  OrganizationInvite = 'ORGANIZATION_INVITE',
  Unknown = 'UNKNOWN'
}

export type INotificationMeta = {
  __typename: 'NotificationMeta';
  commentId?: Maybe<Scalars['String']>;
  documentId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  triggeredByUserId?: Maybe<Scalars['String']>;
};

export type INotificationMetaInput = {
  commentId?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  triggeredByUserId?: InputMaybe<Scalars['String']>;
};

export type INotificationPreferences = {
  __typename: 'NotificationPreferences';
  browser: Scalars['Boolean'];
  email: Scalars['Boolean'];
  inApp: Scalars['Boolean'];
  key: INotificationKey;
};

export type INotificationPreferencesInput = {
  browser: Scalars['Boolean'];
  email: Scalars['Boolean'];
  inApp: Scalars['Boolean'];
  key: INotificationKey;
};

export type INotificationResponse = {
  __typename: 'NotificationResponse';
  category?: Maybe<INotificationCategory>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key?: Maybe<INotificationKey>;
  meta?: Maybe<INotificationMeta>;
  projectId?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
};

export type INotificationResponseConnection = {
  __typename: 'NotificationResponseConnection';
  edges?: Maybe<Array<Maybe<INotificationResponseConnectionEdge>>>;
  pageInfo?: Maybe<IPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type INotificationResponseConnectionEdge = {
  __typename: 'NotificationResponseConnectionEdge';
  cursor: Scalars['String'];
  node: INotificationResponse;
};

export enum IOnboardingStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

export type IOrganizationInputInput = {
  alias?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  logoAssetUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type IOrganizationMembersWithAggregation = {
  __typename: 'OrganizationMembersWithAggregation';
  groupsCount?: Maybe<Scalars['Int']>;
  membersResponse?: Maybe<IMemberResponse>;
  projectsCount?: Maybe<Scalars['Int']>;
  relation?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type IOrganizationMembersWithAggregationConnection = {
  __typename: 'OrganizationMembersWithAggregationConnection';
  edges?: Maybe<Array<Maybe<IOrganizationMembersWithAggregationConnectionEdge>>>;
  pageInfo?: Maybe<IPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IOrganizationMembersWithAggregationConnectionEdge = {
  __typename: 'OrganizationMembersWithAggregationConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<IOrganizationMembersWithAggregation>;
};

export type IOrganizationQuotaPlan = {
  __typename: 'OrganizationQuotaPlan';
  availableSystemRoles?: Maybe<Scalars['Int']>;
  maxSeats?: Maybe<Scalars['Int']>;
};

export type IOrganizationResponse = {
  __typename: 'OrganizationResponse';
  alias?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logoAssetUrl?: Maybe<Scalars['String']>;
  memberStatus?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  roleByUser?: Maybe<IAnyToRoleConnection>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type IOrganizationResponseConnection = {
  __typename: 'OrganizationResponseConnection';
  edges?: Maybe<Array<Maybe<IOrganizationResponseConnectionEdge>>>;
  pageInfo?: Maybe<IPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IOrganizationResponseConnectionEdge = {
  __typename: 'OrganizationResponseConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<IOrganizationResponse>;
};

export type IPageInfo = {
  __typename: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
};

export type IPaginationArgumentsForwardInput = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type IPaginationArgumentsInput = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export enum IPermissionEnum {
  Read = 'READ',
  Write = 'WRITE'
}

export type IPermissionSet = {
  __typename: 'PermissionSet';
  action?: Maybe<Scalars['String']>;
  allow?: Maybe<Scalars['Boolean']>;
  domain?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
};

export type IPortError = {
  __typename: 'PortError';
  errorMessage?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['String']>;
  objectType?: Maybe<Scalars['String']>;
};

export enum IPortType {
  PortTypeImportToProject = 'PORT_TYPE_IMPORT_TO_PROJECT',
  PortTypeUnknown = 'PORT_TYPE_UNKNOWN'
}

export type IPreviewConnection = {
  __typename: 'PreviewConnection';
  edges?: Maybe<Array<Maybe<IPreviewConnectionEdge>>>;
  pageInfo?: Maybe<IPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IPreviewConnectionEdge = {
  __typename: 'PreviewConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<IPreviewWithItemsResponse>;
};

export type IPreviewInputInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type IPreviewItemInputInput = {
  blueprintId?: InputMaybe<Scalars['String']>;
  enableLivePreview?: InputMaybe<Scalars['Boolean']>;
  previewUrl?: InputMaybe<Scalars['String']>;
};

export type IPreviewItemResponse = {
  __typename: 'PreviewItemResponse';
  blueprintId?: Maybe<Scalars['String']>;
  enableLivePreview?: Maybe<Scalars['Boolean']>;
  previewId?: Maybe<Scalars['String']>;
  previewItemId?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
};

export type IPreviewItemUpsertInputInput = {
  blueprintId?: InputMaybe<Scalars['String']>;
  enableLivePreview?: InputMaybe<Scalars['Boolean']>;
  previewItemId?: InputMaybe<Scalars['String']>;
  previewUrl?: InputMaybe<Scalars['String']>;
};

export type IPreviewResponse = {
  __typename: 'PreviewResponse';
  name?: Maybe<Scalars['String']>;
  previewId?: Maybe<Scalars['String']>;
};

export type IPreviewUpsertInputInput = {
  name?: InputMaybe<Scalars['String']>;
  previewId?: InputMaybe<Scalars['String']>;
  previewItems?: InputMaybe<Array<InputMaybe<IPreviewItemUpsertInputInput>>>;
};

export type IPreviewWithItemsResponse = {
  __typename: 'PreviewWithItemsResponse';
  name?: Maybe<Scalars['String']>;
  previewId?: Maybe<Scalars['String']>;
  previewItems?: Maybe<Array<Maybe<IPreviewItemResponse>>>;
};

export type IProjectInputInput = {
  alias?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  logoAssetUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type IProjectMembersWithAggregation = {
  __typename: 'ProjectMembersWithAggregation';
  membersResponse?: Maybe<IAggregatedMemberResponse>;
  relation?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type IProjectMembersWithAggregationConnection = {
  __typename: 'ProjectMembersWithAggregationConnection';
  edges?: Maybe<Array<Maybe<IProjectMembersWithAggregationConnectionEdge>>>;
  pageInfo?: Maybe<IPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IProjectMembersWithAggregationConnectionEdge = {
  __typename: 'ProjectMembersWithAggregationConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<IProjectMembersWithAggregation>;
};

export type IProjectOnboarding = {
  __typename: 'ProjectOnboarding';
  completedEvents?: Maybe<Array<Maybe<Scalars['Int']>>>;
  status?: Maybe<IOnboardingStatus>;
};

export type IProjectPortSelectionInput = {
  blueprint?: InputMaybe<Scalars['Boolean']>;
  document?: InputMaybe<Scalars['Boolean']>;
  documentFieldLocale?: InputMaybe<Scalars['Boolean']>;
  documentHistory?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<Scalars['Boolean']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  tag?: InputMaybe<Scalars['Boolean']>;
  view?: InputMaybe<Scalars['Boolean']>;
  webhook?: InputMaybe<Scalars['Boolean']>;
};

export type IProjectPortSelectionProgress = {
  __typename: 'ProjectPortSelectionProgress';
  blueprintCompleted?: Maybe<Scalars['Int']>;
  blueprintTotal?: Maybe<Scalars['Int']>;
  documentCompleted?: Maybe<Scalars['Int']>;
  documentFieldLocaleCompleted?: Maybe<Scalars['Int']>;
  documentFieldLocaleTotal?: Maybe<Scalars['Int']>;
  documentTotal?: Maybe<Scalars['Int']>;
  memberCompleted?: Maybe<Scalars['Int']>;
  memberTotal?: Maybe<Scalars['Int']>;
  previewCompleted?: Maybe<Scalars['Int']>;
  previewTotal?: Maybe<Scalars['Int']>;
  tagCompleted?: Maybe<Scalars['Int']>;
  tagTotal?: Maybe<Scalars['Int']>;
  viewCompleted?: Maybe<Scalars['Int']>;
  viewTotal?: Maybe<Scalars['Int']>;
  webhookCompleted?: Maybe<Scalars['Int']>;
  webhookTotal?: Maybe<Scalars['Int']>;
};

export type IProjectQuotaPlan = {
  __typename: 'ProjectQuotaPlan';
  availableSystemRoles?: Maybe<Scalars['Int']>;
  maxApiCallsExternal?: Maybe<Scalars['Int']>;
  maxApiCallsInteral?: Maybe<Scalars['Int']>;
  maxAssetBlueprints?: Maybe<Scalars['Int']>;
  maxAssetDownloadTraffic?: Maybe<Scalars['Int']>;
  maxAssetStorage?: Maybe<Scalars['Int']>;
  maxAssetUploadTraffic?: Maybe<Scalars['Int']>;
  maxComponentBlueprints?: Maybe<Scalars['Int']>;
  maxDaysAuditlog?: Maybe<Scalars['Int']>;
  maxDaysWebhookCalls?: Maybe<Scalars['Int']>;
  maxDocumentBlueprints?: Maybe<Scalars['Int']>;
  maxDocuments?: Maybe<Scalars['Int']>;
  maxFieldConnections?: Maybe<Scalars['Int']>;
  maxFieldsPerBlueprint?: Maybe<Scalars['Int']>;
  maxLocales?: Maybe<Scalars['Int']>;
  maxPreviews?: Maybe<Scalars['Int']>;
  maxReleases?: Maybe<Scalars['Int']>;
  maxStoredDocumentVersions?: Maybe<Scalars['Int']>;
  maxViews?: Maybe<Scalars['Int']>;
  maxWebhooks?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type IProjectResponse = {
  __typename: 'ProjectResponse';
  alias?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  logoAssetUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  roleByUser?: Maybe<IAnyToRoleConnection>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type IProjectResponseConnection = {
  __typename: 'ProjectResponseConnection';
  edges?: Maybe<Array<Maybe<IProjectResponseConnectionEdge>>>;
  pageInfo?: Maybe<IPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IProjectResponseConnectionEdge = {
  __typename: 'ProjectResponseConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<IProjectResponse>;
};

export type IProjectSettings = {
  __typename: 'ProjectSettings';
  defaultExternalVersion?: Maybe<Scalars['String']>;
  publicTemplate?: Maybe<Scalars['Boolean']>;
  unautherizedQuries?: Maybe<Scalars['Boolean']>;
};

export type IProjectSettingsInput = {
  defaultExternalVersion?: InputMaybe<Scalars['String']>;
  publicTemplate?: InputMaybe<Scalars['Boolean']>;
  unautherizedQuries?: InputMaybe<Scalars['Boolean']>;
};

export type IProjectTemplatePortSelection = {
  __typename: 'ProjectTemplatePortSelection';
  blueprint?: Maybe<Scalars['Boolean']>;
  document?: Maybe<Scalars['Boolean']>;
  documentFieldLocale?: Maybe<Scalars['Boolean']>;
  documentHistory?: Maybe<Scalars['Boolean']>;
  preview?: Maybe<Scalars['Boolean']>;
  tag?: Maybe<Scalars['Boolean']>;
  view?: Maybe<Scalars['Boolean']>;
  webhook?: Maybe<Scalars['Boolean']>;
};

export type IProjectTemplatePortSelectionInput = {
  blueprint?: InputMaybe<Scalars['Boolean']>;
  document?: InputMaybe<Scalars['Boolean']>;
  documentFieldLocale?: InputMaybe<Scalars['Boolean']>;
  documentHistory?: InputMaybe<Scalars['Boolean']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  tag?: InputMaybe<Scalars['Boolean']>;
  view?: InputMaybe<Scalars['Boolean']>;
  webhook?: InputMaybe<Scalars['Boolean']>;
};

export type IPutManyBlueprintsError = {
  __typename: 'PutManyBlueprintsError';
  blueprintId?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type IPutManyBlueprintsRequestInput = {
  blueprintInputs?: InputMaybe<Array<InputMaybe<IBlueprintUpsertInputInput>>>;
  dryRun?: InputMaybe<Scalars['Boolean']>;
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IPutManyBlueprintsResponse = {
  __typename: 'PutManyBlueprintsResponse';
  changeSet?: Maybe<Array<Maybe<IBlueprintChangeSet>>>;
  errors?: Maybe<Array<Maybe<IPutManyBlueprintsError>>>;
  successfulBlueprintIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IPutManyDocumentFieldLocalesError = {
  __typename: 'PutManyDocumentFieldLocalesError';
  documentFieldLocaleId?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type IPutManyDocumentFieldLocalesRequestInput = {
  documentFieldLocaleInputs?: InputMaybe<Array<InputMaybe<IDocumentFieldLocaleUpsertInputInput>>>;
  dryRun?: InputMaybe<Scalars['Boolean']>;
  overwriteDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IPutManyDocumentFieldLocalesResponse = {
  __typename: 'PutManyDocumentFieldLocalesResponse';
  changeSet?: Maybe<Array<Maybe<IDocumentFieldLocaleChangeSet>>>;
  errors?: Maybe<Array<Maybe<IPutManyDocumentFieldLocalesError>>>;
  successfulDocumentFieldLocaleIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IPutManyDocumentsError = {
  __typename: 'PutManyDocumentsError';
  documentId?: Maybe<Scalars['String']>;
  documentIndex?: Maybe<Scalars['Int']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type IPutManyDocumentsRequestInput = {
  documentInputs?: InputMaybe<Array<InputMaybe<IDocumentWithFieldsInput>>>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IPutManyDocumentsResponse = {
  __typename: 'PutManyDocumentsResponse';
  errors?: Maybe<Array<Maybe<IPutManyDocumentsError>>>;
  successfulDocumentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IPutManyPreviewsError = {
  __typename: 'PutManyPreviewsError';
  errorMessage?: Maybe<Scalars['String']>;
  previewId?: Maybe<Scalars['String']>;
};

export type IPutManyPreviewsRequestInput = {
  previewInputs?: InputMaybe<Array<InputMaybe<IPreviewUpsertInputInput>>>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IPutManyPreviewsResponse = {
  __typename: 'PutManyPreviewsResponse';
  errors?: Maybe<Array<Maybe<IPutManyPreviewsError>>>;
  successfulPreviewIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IPutManyTagsError = {
  __typename: 'PutManyTagsError';
  errorMessage?: Maybe<Scalars['String']>;
  tagId?: Maybe<Scalars['String']>;
};

export type IPutManyTagsRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
  tagInputs?: InputMaybe<Array<InputMaybe<ITagUpsertInputInput>>>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IPutManyTagsResponse = {
  __typename: 'PutManyTagsResponse';
  errors?: Maybe<Array<Maybe<IPutManyTagsError>>>;
  successfulTagIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IPutManyViewsError = {
  __typename: 'PutManyViewsError';
  errorMessage?: Maybe<Scalars['String']>;
  viewId?: Maybe<Scalars['String']>;
};

export type IPutManyViewsRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
  viewInputs?: InputMaybe<Array<InputMaybe<IViewUpsertInputInput>>>;
};

export type IPutManyViewsResponse = {
  __typename: 'PutManyViewsResponse';
  errors?: Maybe<Array<Maybe<IPutManyViewsError>>>;
  successfulViewIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IPutManyWebhooksRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
  webhooks?: InputMaybe<Array<InputMaybe<IWebhookUpsertInputInput>>>;
};

export type IPutManyWebhooksResponse = {
  __typename: 'PutManyWebhooksResponse';
  webhooks?: Maybe<Array<Maybe<IWebhookResponse>>>;
};

export type IQuery = {
  __typename: 'Query';
  GetAllAmounts?: Maybe<IGetAllAmountsResponse>;
  GetAllDocumentFieldLocale?: Maybe<IGetAllDocumentFieldLocaleResponse>;
  GetAllDocumentSnapshot?: Maybe<IGetAllDocumentSnapshotResponse>;
  GetAllDocumentStatus?: Maybe<IGetAllDocumentStatusResponse>;
  GetAllWebhooks?: Maybe<IGetAllWebhooksResponse>;
  GetAmount?: Maybe<IGetAmountResponse>;
  GetBlueprintById?: Maybe<IGetBlueprintByIdResponse>;
  GetBlueprintByName?: Maybe<IGetBlueprintByNameResponse>;
  GetCommentById?: Maybe<IGetCommentByIdResponse>;
  GetCounts?: Maybe<IGetCountsResponse>;
  GetDocumentById?: Maybe<IGetDocumentByIdResponse>;
  GetDocumentCountsByBlueprints?: Maybe<IGetDocumentCountsByBlueprintsResponse>;
  GetDocumentReferences?: Maybe<IGetDocumentReferencesResponse>;
  GetDocumentSnapshot?: Maybe<IGetDocumentSnapshotResponse>;
  GetEntrypointByDomain?: Maybe<IGetEntrypointByDomainResponse>;
  GetEntrypointByScope?: Maybe<IGetEntrypointByScopeResponse>;
  GetGroupByID?: Maybe<IGetGroupByIdResponse>;
  GetGroupByIDWithInheritance?: Maybe<IGetGroupByIdWithInheritanceResponse>;
  GetGroupMembers?: Maybe<IGetGroupMembersResponse>;
  GetGroupMembersWithAggregation?: Maybe<IGetGroupMembersWithAggregationResponse>;
  GetManyApiKey?: Maybe<IGetManyApiKeyResponse>;
  GetManyAuditEvents?: Maybe<IGetManyAuditEventsResponse>;
  GetManyBlueprints?: Maybe<IGetManyBlueprintsResponse>;
  GetManyComments?: Maybe<IGetManyCommentsResponse>;
  GetManyDocumentFieldsByDocumentId?: Maybe<IGetManyDocumentFieldsByDocumentIdResponse>;
  GetManyDocumentFieldsByFieldIds?: Maybe<IGetManyDocumentFieldsByFieldIdsResponse>;
  GetManyDocuments?: Maybe<IGetManyDocumentsResponse>;
  GetManyDocumentsByIds?: Maybe<IGetManyDocumentsByIdsResponse>;
  GetManyGroupsByUserID?: Maybe<IGetManyGroupsByUserIdResponse>;
  GetManyInvitations?: Maybe<IGetManyInvitationsResponse>;
  GetManyNotifications?: Maybe<IGetManyNotificationsResponse>;
  GetManyOrganizationsByUserID?: Maybe<IGetManyOrganizationsByUserIdResponse>;
  GetManyPreviews?: Maybe<IGetManyPreviewsResponse>;
  GetManyProjectQuotaOverviewByID?: Maybe<IGetManyProjectQuotaOverviewByIdResponse>;
  GetManyProjectsByUserID?: Maybe<IGetManyProjectsByUserIdResponse>;
  GetManyReleasesByProjectId?: Maybe<IGetManyReleasesByProjectIdResponse>;
  GetManyRoleRulesByRoleID?: Maybe<IGetManyRoleRulesByRoleIdResponse>;
  GetManyTags?: Maybe<IGetManyTagsResponse>;
  GetManyUserRoles?: Maybe<IGetManyUserRolesResponse>;
  GetManyUsersProfile?: Maybe<IGetManyUsersProfileResponse>;
  GetManyViewPins?: Maybe<IGetManyViewPinsResponse>;
  GetManyViews?: Maybe<IGetManyViewsResponse>;
  GetManyWebhookCalls?: Maybe<IGetManyWebhookCallsResponse>;
  GetOrganizationByID?: Maybe<IGetOrganizationByIdResponse>;
  GetOrganizationMembers?: Maybe<IGetOrganizationMembersResponse>;
  GetOrganizationMembersWithAggregation?: Maybe<IGetOrganizationMembersWithAggregationResponse>;
  GetOrganizationQuotaPlan?: Maybe<IGetOrganizationQuotaPlanResponse>;
  GetPaymentInfo?: Maybe<IGetPaymentInfoResponse>;
  GetPaymentStatus?: Maybe<IGetPaymentStatusResponse>;
  GetPermissionGrant?: Maybe<IGetPermissionGrantResponse>;
  GetPermissionSet?: Maybe<IGetPermissionSetResponse>;
  GetPinnedProjects?: Maybe<IGetPinnedProjectsResponse>;
  GetPreviewById?: Maybe<IGetPreviewByIdResponse>;
  GetPreviewItemsByBlueprintId?: Maybe<IGetPreviewItemsByBlueprintIdResponse>;
  GetProjectBillingStatus?: Maybe<IGetProjectBillingStatusResponse>;
  GetProjectByID?: Maybe<IGetProjectByIdResponse>;
  GetProjectByIDWithInheritance?: Maybe<IGetProjectByIdWithInheritanceResponse>;
  GetProjectMembers?: Maybe<IGetProjectMembersResponse>;
  GetProjectMembersWithAggregation?: Maybe<IGetProjectMembersWithAggregationResponse>;
  GetProjectOnboarding?: Maybe<IGetProjectOnboardingResponse>;
  GetProjectPort?: Maybe<IGetProjectPortResponse>;
  GetProjectQuotaPlan?: Maybe<IGetProjectQuotaPlanResponse>;
  GetProjectSettings?: Maybe<IGetProjectSettingsResponse>;
  GetProjectTemplateSettings?: Maybe<IGetProjectTemplateSettingsResponse>;
  GetProjectTemplateUsage?: Maybe<IGetProjectTemplateUsageResponse>;
  GetRecentActiveProjects?: Maybe<IGetRecentActiveProjectsResponse>;
  GetReleaseById?: Maybe<IGetReleaseByIdResponse>;
  GetRoleRuleByID?: Maybe<IGetRoleRuleByIdResponse>;
  GetUnpublishedLinkedDocuments?: Maybe<IGetUnpublishedLinkedDocumentsResponse>;
  GetUsageOfBlueprintField?: Maybe<IGetUsageOfBlueprintFieldResponse>;
  GetUserInformation?: Maybe<IGetUserInformationResponse>;
  GetUserOnboarding?: Maybe<IGetUserOnboardingResponse>;
  GetUserRoleWithRoleRules?: Maybe<IGetUserRoleWithRoleRulesResponse>;
  GetWebhookCall?: Maybe<IGetWebhookCallResponse>;
  ReadUserAccessTokens?: Maybe<IReadUserAccessTokensResponse>;
  ValidateApiKey?: Maybe<IValidateApiKeyResponse>;
  ValidateProjectDiscountCode?: Maybe<IValidateProjectDiscountCodeResponse>;
  _service: I_Service;
  getUser?: Maybe<IGetUserResponse>;
  session?: Maybe<ISession>;
  userProfile?: Maybe<ICaisyUserProfile>;
};


export type IQueryGetAllAmountsArgs = {
  input?: InputMaybe<IGetAllAmountsRequestInput>;
};


export type IQueryGetAllDocumentFieldLocaleArgs = {
  input?: InputMaybe<IGetAllDocumentFieldLocaleRequestInput>;
};


export type IQueryGetAllDocumentSnapshotArgs = {
  input?: InputMaybe<IGetAllDocumentSnapshotRequestInput>;
};


export type IQueryGetAllDocumentStatusArgs = {
  input?: InputMaybe<IGetAllDocumentStatusRequestInput>;
};


export type IQueryGetAllWebhooksArgs = {
  input?: InputMaybe<IGetAllWebhooksRequestInput>;
};


export type IQueryGetAmountArgs = {
  input?: InputMaybe<IGetAmountRequestInput>;
};


export type IQueryGetBlueprintByIdArgs = {
  input?: InputMaybe<IGetBlueprintByIdRequest>;
};


export type IQueryGetBlueprintByNameArgs = {
  input?: InputMaybe<IGetBlueprintByNameRequest>;
};


export type IQueryGetCommentByIdArgs = {
  input?: InputMaybe<IGetCommentByIdRequestInput>;
};


export type IQueryGetCountsArgs = {
  input?: InputMaybe<IGetCountsRequestInput>;
};


export type IQueryGetDocumentByIdArgs = {
  input?: InputMaybe<IGetDocumentByIdRequestInput>;
};


export type IQueryGetDocumentCountsByBlueprintsArgs = {
  input?: InputMaybe<IGetDocumentCountsByBlueprintsRequestInput>;
};


export type IQueryGetDocumentReferencesArgs = {
  input?: InputMaybe<IGetDocumentReferencesRequestInput>;
};


export type IQueryGetDocumentSnapshotArgs = {
  input?: InputMaybe<IGetDocumentSnapshotRequestInput>;
};


export type IQueryGetEntrypointByDomainArgs = {
  input?: InputMaybe<IGetEntrypointByDomainRequestInput>;
};


export type IQueryGetEntrypointByScopeArgs = {
  input?: InputMaybe<IGetEntrypointByScopeRequestInput>;
};


export type IQueryGetGroupByIdArgs = {
  input?: InputMaybe<IGetGroupByIdRequestInput>;
};


export type IQueryGetGroupByIdWithInheritanceArgs = {
  input?: InputMaybe<IGetGroupByIdWithInheritanceRequestInput>;
};


export type IQueryGetGroupMembersArgs = {
  input?: InputMaybe<IGetGroupMembersRequestInput>;
};


export type IQueryGetGroupMembersWithAggregationArgs = {
  input?: InputMaybe<IGetGroupMembersWithAggregationRequestInput>;
};


export type IQueryGetManyApiKeyArgs = {
  input?: InputMaybe<IGetManyApiKeyRequestInput>;
};


export type IQueryGetManyAuditEventsArgs = {
  input?: InputMaybe<IGetManyAuditEventsRequestInput>;
};


export type IQueryGetManyBlueprintsArgs = {
  input?: InputMaybe<IGetManyBlueprintsRequest>;
};


export type IQueryGetManyCommentsArgs = {
  input?: InputMaybe<IGetManyCommentsRequestInput>;
};


export type IQueryGetManyDocumentFieldsByDocumentIdArgs = {
  input?: InputMaybe<IGetManyDocumentFieldsByDocumentIdRequestInput>;
};


export type IQueryGetManyDocumentFieldsByFieldIdsArgs = {
  input?: InputMaybe<IGetManyDocumentFieldsByFieldIdsRequestInput>;
};


export type IQueryGetManyDocumentsArgs = {
  input?: InputMaybe<IGetManyDocumentsRequestInput>;
};


export type IQueryGetManyDocumentsByIdsArgs = {
  input?: InputMaybe<IGetManyDocumentsByIdsRequestInput>;
};


export type IQueryGetManyGroupsByUserIdArgs = {
  input?: InputMaybe<IGetManyGroupsByUserIdRequestInput>;
};


export type IQueryGetManyInvitationsArgs = {
  input?: InputMaybe<IGetManyInvitationsRequestInput>;
};


export type IQueryGetManyNotificationsArgs = {
  input?: InputMaybe<IGetManyNotificationsRequestInput>;
};


export type IQueryGetManyOrganizationsByUserIdArgs = {
  input?: InputMaybe<IGetManyOrganizationsByUserIdRequestInput>;
};


export type IQueryGetManyPreviewsArgs = {
  input?: InputMaybe<IGetManyPreviewsRequestInput>;
};


export type IQueryGetManyProjectQuotaOverviewByIdArgs = {
  input?: InputMaybe<IGetManyProjectQuotaOverviewByIdRequestInput>;
};


export type IQueryGetManyProjectsByUserIdArgs = {
  input?: InputMaybe<IGetManyProjectsByUserIdRequestInput>;
};


export type IQueryGetManyReleasesByProjectIdArgs = {
  input?: InputMaybe<IGetManyReleasesByProjectIdRequestInput>;
};


export type IQueryGetManyRoleRulesByRoleIdArgs = {
  input?: InputMaybe<IGetManyRoleRulesByRoleIdRequestInput>;
};


export type IQueryGetManyTagsArgs = {
  input?: InputMaybe<IGetManyTagsRequest>;
};


export type IQueryGetManyUserRolesArgs = {
  input?: InputMaybe<IGetManyUserRolesRequestInput>;
};


export type IQueryGetManyUsersProfileArgs = {
  input?: InputMaybe<IGetManyUsersProfileRequestInput>;
};


export type IQueryGetManyViewPinsArgs = {
  input?: InputMaybe<IGetManyViewPinsRequestInput>;
};


export type IQueryGetManyViewsArgs = {
  input?: InputMaybe<IGetManyViewsRequestInput>;
};


export type IQueryGetManyWebhookCallsArgs = {
  input?: InputMaybe<IGetManyWebhookCallsRequestInput>;
};


export type IQueryGetOrganizationByIdArgs = {
  input?: InputMaybe<IGetOrganizationByIdRequestInput>;
};


export type IQueryGetOrganizationMembersArgs = {
  input?: InputMaybe<IGetOrganizationMembersRequestInput>;
};


export type IQueryGetOrganizationMembersWithAggregationArgs = {
  input?: InputMaybe<IGetOrganizationMembersWithAggregationRequestInput>;
};


export type IQueryGetOrganizationQuotaPlanArgs = {
  input?: InputMaybe<IGetOrganizationQuotaPlanRequestInput>;
};


export type IQueryGetPaymentInfoArgs = {
  input?: InputMaybe<IGetPaymentInfoRequestInput>;
};


export type IQueryGetPaymentStatusArgs = {
  input?: InputMaybe<IGetPaymentStatusInput>;
};


export type IQueryGetPermissionGrantArgs = {
  input?: InputMaybe<IGetPermissionGrantRequestInput>;
};


export type IQueryGetPermissionSetArgs = {
  input?: InputMaybe<IGetPermissionSetRequestInput>;
};


export type IQueryGetPinnedProjectsArgs = {
  input?: InputMaybe<IGetPinnedProjectsRequestInput>;
};


export type IQueryGetPreviewByIdArgs = {
  input?: InputMaybe<IGetPreviewByIdRequestInput>;
};


export type IQueryGetPreviewItemsByBlueprintIdArgs = {
  input?: InputMaybe<IGetPreviewItemsByBlueprintIdRequestInput>;
};


export type IQueryGetProjectBillingStatusArgs = {
  input?: InputMaybe<IGetProjectBillingStatusRequestInput>;
};


export type IQueryGetProjectByIdArgs = {
  input?: InputMaybe<IGetProjectByIdRequestInput>;
};


export type IQueryGetProjectByIdWithInheritanceArgs = {
  input?: InputMaybe<IGetProjectByIdWithInheritanceRequestInput>;
};


export type IQueryGetProjectMembersArgs = {
  input?: InputMaybe<IGetProjectMembersRequestInput>;
};


export type IQueryGetProjectMembersWithAggregationArgs = {
  input?: InputMaybe<IGetProjectMembersWithAggregationRequestInput>;
};


export type IQueryGetProjectOnboardingArgs = {
  input?: InputMaybe<IGetProjectOnboardingRequestInput>;
};


export type IQueryGetProjectPortArgs = {
  input?: InputMaybe<IGetProjectPortRequestInput>;
};


export type IQueryGetProjectQuotaPlanArgs = {
  input?: InputMaybe<IGetProjectQuotaPlanRequestInput>;
};


export type IQueryGetProjectSettingsArgs = {
  input?: InputMaybe<IGetProjectSettingsRequestInput>;
};


export type IQueryGetProjectTemplateSettingsArgs = {
  input?: InputMaybe<IGetProjectTemplateSettingsRequestInput>;
};


export type IQueryGetProjectTemplateUsageArgs = {
  input?: InputMaybe<IGetProjectTemplateUsageRequestInput>;
};


export type IQueryGetRecentActiveProjectsArgs = {
  input?: InputMaybe<IGetRecentActiveProjectsRequestInput>;
};


export type IQueryGetReleaseByIdArgs = {
  input?: InputMaybe<IGetReleaseByIdRequestInput>;
};


export type IQueryGetRoleRuleByIdArgs = {
  input?: InputMaybe<IGetRoleRuleByIdRequestInput>;
};


export type IQueryGetUnpublishedLinkedDocumentsArgs = {
  input?: InputMaybe<IGetUnpublishedLinkedDocumentsRequestInput>;
};


export type IQueryGetUsageOfBlueprintFieldArgs = {
  input?: InputMaybe<IGetUsageOfBlueprintFieldRequestInput>;
};


export type IQueryGetUserInformationArgs = {
  input?: InputMaybe<IGetUserInformationRequestInput>;
};


export type IQueryGetUserOnboardingArgs = {
  input?: InputMaybe<IGetUserOnboardingRequestInput>;
};


export type IQueryGetUserRoleWithRoleRulesArgs = {
  input?: InputMaybe<IGetUserRoleWithRoleRulesRequestInput>;
};


export type IQueryGetWebhookCallArgs = {
  input?: InputMaybe<IGetWebhookCallRequestInput>;
};


export type IQueryReadUserAccessTokensArgs = {
  input?: InputMaybe<IReadUserAccessTokensRequestInput>;
};


export type IQueryValidateApiKeyArgs = {
  input?: InputMaybe<IValidateApiKeyRequestInput>;
};


export type IQueryValidateProjectDiscountCodeArgs = {
  input?: InputMaybe<IValidateProjectDiscountCodeRequestInput>;
};


export type IQueryUserProfileArgs = {
  userId: Scalars['ID'];
};

export type IQuotaCount = {
  __typename: 'QuotaCount';
  count?: Maybe<Scalars['BigInt']>;
};

export type IQuotaFilterInput = {
  operation?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export enum IQuotaInterval {
  QuoutaIntervalDayly = 'QUOUTA_INTERVAL_DAYLY',
  QuoutaIntervalUnspecified = 'QUOUTA_INTERVAL_UNSPECIFIED'
}

export type IQuotaOverviewReponse = {
  __typename: 'QuotaOverviewReponse';
  amounts?: Maybe<Array<Maybe<IAmountItem>>>;
  projectId?: Maybe<Scalars['String']>;
  quotaPlan?: Maybe<IProjectQuotaPlan>;
};

export type IQuotaPaginationInput = {
  endDate?: InputMaybe<IDateInput>;
  interval?: InputMaybe<IQuotaInterval>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type IReActivateOrganizationMemberRequestInput = {
  organizationId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IReActivateOrganizationMemberResponse = {
  __typename: 'ReActivateOrganizationMemberResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type IReadUserAccessTokensRequestInput = {
  userId?: InputMaybe<Scalars['String']>;
};

export type IReadUserAccessTokensResponse = {
  __typename: 'ReadUserAccessTokensResponse';
  tokens?: Maybe<Array<Maybe<IAccessTokenResponse>>>;
};

export enum IReferenceType {
  Blueprint = 'BLUEPRINT',
  Document = 'DOCUMENT'
}

export type IReleaseDocumentFieldErrors = {
  __typename: 'ReleaseDocumentFieldErrors';
  blueprintFieldId?: Maybe<Scalars['String']>;
  documentFieldLocaleId?: Maybe<Scalars['String']>;
  errorCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IReleaseFilterInput = {
  documentId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Time']>;
  startDate?: InputMaybe<Scalars['Time']>;
  status?: InputMaybe<IReleaseStatus>;
};

export type IReleaseInputInput = {
  name?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  scheduledAt?: InputMaybe<Scalars['Time']>;
  status?: InputMaybe<IReleaseStatus>;
  type?: InputMaybe<IReleaseType>;
};

export type IReleaseResponse = {
  __typename: 'ReleaseResponse';
  createdAt?: Maybe<Scalars['Time']>;
  documentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['Time']>;
  status?: Maybe<IReleaseStatus>;
  type?: Maybe<IReleaseType>;
  updatedAt?: Maybe<Scalars['Time']>;
  validationErrors?: Maybe<Array<Maybe<IReleaseValidationDocumentErrors>>>;
};

export enum IReleaseStatus {
  Failed = 'FAILED',
  Scheduled = 'SCHEDULED',
  Successful = 'SUCCESSFUL',
  Unscheduled = 'UNSCHEDULED'
}

export enum IReleaseType {
  Publish = 'PUBLISH',
  Unpublish = 'UNPUBLISH'
}

export type IReleaseValidationDocumentErrors = {
  __typename: 'ReleaseValidationDocumentErrors';
  documentId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<IReleaseDocumentFieldErrors>>>;
};

export type IResendOrganizationInvitationRequestInput = {
  memberId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type IResendOrganizationInvitationResponse = {
  __typename: 'ResendOrganizationInvitationResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type IResetProjectOnboardingRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
};

export type IResetProjectOnboardingResponse = {
  __typename: 'ResetProjectOnboardingResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type IResetUserOnboardingRequestInput = {
  userId?: InputMaybe<Scalars['String']>;
};

export type IResetUserOnboardingResponse = {
  __typename: 'ResetUserOnboardingResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type IRole = {
  __typename: 'Role';
  description?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  inheritedBySystemRole?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
  systemRole?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type IRoleRuleInputInput = {
  action?: InputMaybe<Scalars['String']>;
  allow?: InputMaybe<Scalars['Boolean']>;
  domain?: InputMaybe<Scalars['String']>;
  object?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['String']>;
  target?: InputMaybe<Scalars['String']>;
};

export type IRoleRuleResponse = {
  __typename: 'RoleRuleResponse';
  action?: Maybe<Scalars['String']>;
  allow?: Maybe<Scalars['Boolean']>;
  domain?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
  ruleId?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
};

export type ISession = {
  __typename: 'Session';
  activeProjectId?: Maybe<Scalars['String']>;
  audience?: Maybe<Scalars['String']>;
  authTime?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  expires?: Maybe<Scalars['Int']>;
  issuedAt?: Maybe<Scalars['Int']>;
  issuer?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
};

export type ISetCommentPriorityRequestInput = {
  commentId: Scalars['String'];
  priority: Scalars['Int'];
  projectId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type ISetCommentPriorityResponse = {
  __typename: 'SetCommentPriorityResponse';
  comment?: Maybe<ICommentResponse>;
};

export type ISetCommentStatusRequestInput = {
  commentId: Scalars['String'];
  projectId: Scalars['String'];
  status: ICommentStatus;
  userId?: InputMaybe<Scalars['String']>;
};

export type ISetCommentStatusResponse = {
  __typename: 'SetCommentStatusResponse';
  comment?: Maybe<ICommentResponse>;
};

export type ISetNotificationPreferencesRequestInput = {
  preferences: INotificationPreferencesInput;
  userId?: InputMaybe<Scalars['String']>;
};

export type ISetNotificationPreferencesResponse = {
  __typename: 'SetNotificationPreferencesResponse';
  preferences: INotificationPreferences;
};

export type ISetPinnedProjectsRequestInput = {
  projectIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ISetPinnedProjectsResponse = {
  __typename: 'SetPinnedProjectsResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type ISetPreferredUiLanguageRequest = {
  preferredUILanguage?: InputMaybe<Scalars['String']>;
};

export type ISetPreferredUiLanguageResponse = {
  __typename: 'SetPreferredUILanguageResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type ISetPreferredUiThemeRequest = {
  preferredUITheme?: InputMaybe<Scalars['Int']>;
};

export type ISetPreferredUiThemeResponse = {
  __typename: 'SetPreferredUIThemeResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type ISetProjectBillingPlanRequestInput = {
  billingPlanName?: InputMaybe<Scalars['String']>;
  discountCode?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ISetProjectBillingPlanResponse = {
  __typename: 'SetProjectBillingPlanResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type ISetProjectOnboardingCompletedRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
};

export type ISetProjectOnboardingCompletedResponse = {
  __typename: 'SetProjectOnboardingCompletedResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type ISetProjectOnboardingEventCompletedRequestInput = {
  eventCode?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type ISetProjectOnboardingEventCompletedResponse = {
  __typename: 'SetProjectOnboardingEventCompletedResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type ISetProjectSettingsRequestInput = {
  projectId: Scalars['String'];
  settings?: InputMaybe<IProjectSettingsInput>;
};

export type ISetProjectSettingsResponse = {
  __typename: 'SetProjectSettingsResponse';
  settings?: Maybe<IProjectSettings>;
};

export type ISetProjectTemplateSettingsRequestInput = {
  details?: InputMaybe<ITemplateDetailsInput>;
  projectId?: InputMaybe<Scalars['String']>;
  publicTemplate?: InputMaybe<Scalars['Boolean']>;
  selection?: InputMaybe<IProjectTemplatePortSelectionInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ISetProjectTemplateSettingsResponse = {
  __typename: 'SetProjectTemplateSettingsResponse';
  details?: Maybe<ITemplateDetails>;
  projectId?: Maybe<Scalars['String']>;
  publicTemplate?: Maybe<Scalars['Boolean']>;
  selection?: Maybe<IProjectTemplatePortSelection>;
  templateId?: Maybe<Scalars['String']>;
};

export type ISetUserActiveProjectRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
};

export type ISetUserActiveProjectResponse = {
  __typename: 'SetUserActiveProjectResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type ISetUserMarketingProfileRequestInput = {
  profile?: InputMaybe<Scalars['Map']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ISetUserMarketingProfileResponse = {
  __typename: 'SetUserMarketingProfileResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type ISetUserOnboardingCompletedRequestInput = {
  userId?: InputMaybe<Scalars['String']>;
};

export type ISetUserOnboardingCompletedResponse = {
  __typename: 'SetUserOnboardingCompletedResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type ISetUserOnboardingEventCompletedRequestInput = {
  eventCode?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ISetUserOnboardingEventCompletedResponse = {
  __typename: 'SetUserOnboardingEventCompletedResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type ISetupPasswordWithActionCodeRequestInput = {
  code: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type ISetupPasswordWithActionCodeResponse = {
  __typename: 'SetupPasswordWithActionCodeResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type ITagConnection = {
  __typename: 'TagConnection';
  edges?: Maybe<Array<Maybe<ITagConnectionEdge>>>;
  pageInfo?: Maybe<IPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ITagConnectionEdge = {
  __typename: 'TagConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ITagResponse>;
};

export type ITagResponse = {
  __typename: 'TagResponse';
  color?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<IReferenceType>;
  tagId?: Maybe<Scalars['String']>;
};

export type ITagUpsertInputInput = {
  color?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  referenceType?: InputMaybe<IReferenceType>;
  tagId?: InputMaybe<Scalars['String']>;
};

export type ITemplateDetails = {
  __typename: 'TemplateDetails';
  fullDescription?: Maybe<Scalars['Any']>;
  metaInformation?: Maybe<Scalars['Any']>;
  name?: Maybe<Scalars['String']>;
  previewImage?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['Any']>;
};

export type ITemplateDetailsInput = {
  fullDescription?: InputMaybe<Scalars['Any']>;
  metaInformation?: InputMaybe<Scalars['Any']>;
  name?: InputMaybe<Scalars['String']>;
  previewImage?: InputMaybe<Scalars['String']>;
  shortDescription?: InputMaybe<Scalars['Any']>;
};

export type ITemplateUsageResponse = {
  __typename: 'TemplateUsageResponse';
  documentationId?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
};

export type ITokens = {
  __typename: 'Tokens';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type IUnlinkManyReleaseDocumentRequestInput = {
  documentId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  projectId?: InputMaybe<Scalars['String']>;
  releaseId?: InputMaybe<Scalars['String']>;
};

export type IUnlinkManyReleaseDocumentResponse = {
  __typename: 'UnlinkManyReleaseDocumentResponse';
  successful?: Maybe<Scalars['Boolean']>;
};

export type IUpdateBlueprintRequest = {
  blueprintId?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<IBlueprintInputInput>;
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IUpdateBlueprintResponse = {
  __typename: 'UpdateBlueprintResponse';
  blueprint?: Maybe<IBlueprintResponse>;
};

export type IUpdateCommentRequestInput = {
  commentId: Scalars['String'];
  content: Scalars['String'];
  mentionedUsers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  projectId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type IUpdateCommentResponse = {
  __typename: 'UpdateCommentResponse';
  comment?: Maybe<ICommentResponse>;
};

export type IUpdateDocumentError = {
  __typename: 'UpdateDocumentError';
  documentId?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type IUpdateDocumentFieldLocaleRequestInput = {
  documentFieldLocaleId?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<IDocumentFieldLocaleInputInput>;
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IUpdateDocumentFieldLocaleResponse = {
  __typename: 'UpdateDocumentFieldLocaleResponse';
  documentFieldLocale?: Maybe<IDocumentFieldLocaleResponse>;
  projectId?: Maybe<Scalars['String']>;
};

export type IUpdateDocumentFieldRequestInput = {
  blueprintBranch?: InputMaybe<Scalars['String']>;
  blueprintFieldId?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['Any']>;
  documentFieldLocaleId?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IUpdateDocumentFieldResponse = {
  __typename: 'UpdateDocumentFieldResponse';
  blueprintFieldId?: Maybe<Scalars['String']>;
  document?: Maybe<IDocument>;
};

export type IUpdateDocumentRequestInput = {
  blueprintBranch?: InputMaybe<Scalars['String']>;
  blueprintId?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
  previewImageUrl?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  statusId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IUpdateDocumentResponse = {
  __typename: 'UpdateDocumentResponse';
  document?: Maybe<IDocument>;
};

export type IUpdateEntrypointRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<IEntrypointInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IUpdateEntrypointResponse = {
  __typename: 'UpdateEntrypointResponse';
  entrypoint?: Maybe<IEntrypointResponse>;
};

export type IUpdateGroupRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<IGroupInputInput>;
  mask?: InputMaybe<IGoogle_Protobuf_FieldMaskInput>;
};

export type IUpdateGroupResponse = {
  __typename: 'UpdateGroupResponse';
  group?: Maybe<IGroupResponse>;
};

export type IUpdateManyDocumentRequestInput = {
  updateDocumentRequests?: InputMaybe<Array<InputMaybe<IUpdateDocumentRequestInput>>>;
};

export type IUpdateManyDocumentsResponse = {
  __typename: 'UpdateManyDocumentsResponse';
  batchErrors?: Maybe<Array<Maybe<IUpdateDocumentError>>>;
  updatedDocumentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IUpdateOrganizationRequestInput = {
  input?: InputMaybe<IOrganizationInputInput>;
  mask?: InputMaybe<IGoogle_Protobuf_FieldMaskInput>;
  organizationId?: InputMaybe<Scalars['String']>;
};

export type IUpdateOrganizationResponse = {
  __typename: 'UpdateOrganizationResponse';
  organization?: Maybe<IOrganizationResponse>;
};

export type IUpdatePreviewItemRequestInput = {
  enableLivePreview?: InputMaybe<Scalars['Boolean']>;
  previewId?: InputMaybe<Scalars['String']>;
  previewItemId?: InputMaybe<Scalars['String']>;
  previewUrl?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IUpdatePreviewItemResponse = {
  __typename: 'UpdatePreviewItemResponse';
  previewItem?: Maybe<IPreviewItemResponse>;
};

export type IUpdatePreviewRequestInput = {
  input?: InputMaybe<IPreviewInputInput>;
  previewId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IUpdatePreviewResponse = {
  __typename: 'UpdatePreviewResponse';
  preview?: Maybe<IPreviewResponse>;
};

export type IUpdateProjectRequestInput = {
  input?: InputMaybe<IProjectInputInput>;
  mask?: InputMaybe<IGoogle_Protobuf_FieldMaskInput>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IUpdateProjectResponse = {
  __typename: 'UpdateProjectResponse';
  project?: Maybe<IProjectResponse>;
};

export type IUpdateQuotaPlanRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  quotaPlanName?: InputMaybe<Scalars['String']>;
};

export type IUpdateQuotaPlanResponse = {
  __typename: 'UpdateQuotaPlanResponse';
  groupId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  quotaPlan?: Maybe<IProjectQuotaPlan>;
};

export type IUpdateReleaseRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
  release?: InputMaybe<IReleaseInputInput>;
  releaseId?: InputMaybe<Scalars['String']>;
};

export type IUpdateReleaseResponse = {
  __typename: 'UpdateReleaseResponse';
  release?: Maybe<IReleaseResponse>;
};

export type IUpdateRoleRuleRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<IRoleRuleInputInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['String']>;
  ruleId?: InputMaybe<Scalars['String']>;
};

export type IUpdateRoleRuleResponse = {
  __typename: 'UpdateRoleRuleResponse';
  roleRule?: Maybe<IRoleRuleResponse>;
};

export type IUpdateTagInput = {
  color?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type IUpdateTagRequest = {
  input?: InputMaybe<IUpdateTagInput>;
  projectId?: InputMaybe<Scalars['String']>;
  tagId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IUpdateTagResponse = {
  __typename: 'UpdateTagResponse';
  tag?: Maybe<ITagResponse>;
};

export type IUpdateUserProfileRequestInput = {
  profile: IUserProfileInput;
};

export type IUpdateUserProfileResponse = {
  __typename: 'UpdateUserProfileResponse';
  success: Scalars['Boolean'];
};

export type IUpdateUserRoleRequestInput = {
  groupId?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<IUserRoleInputInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['String']>;
};

export type IUpdateUserRoleResponse = {
  __typename: 'UpdateUserRoleResponse';
  userRole?: Maybe<IUserRoleResponse>;
};

export type IUpdateViewPinRequestInput = {
  position?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  viewId?: InputMaybe<Scalars['String']>;
};

export type IUpdateViewPinResponse = {
  __typename: 'UpdateViewPinResponse';
  updated?: Maybe<Scalars['Boolean']>;
};

export type IUpdateViewRequestInput = {
  input?: InputMaybe<IViewUpdateInputInput>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IUpdateViewResponse = {
  __typename: 'UpdateViewResponse';
  view?: Maybe<IViewResponse>;
};

export type IUpdateWebhookRequestInput = {
  id?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<IWebhookInputInput>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IUpdateWebhookResponse = {
  __typename: 'UpdateWebhookResponse';
  webhook?: Maybe<IWebhookResponse>;
};

export type IUserInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  username?: InputMaybe<Scalars['String']>;
};

export type IUserMarketingProfile = {
  __typename: 'UserMarketingProfile';
  complete?: Maybe<Scalars['Boolean']>;
};

export type IUserOnboarding = {
  __typename: 'UserOnboarding';
  completedEvents?: Maybe<Array<Maybe<Scalars['Int']>>>;
  status?: Maybe<IOnboardingStatus>;
};

export type IUserProfile = {
  __typename: 'UserProfile';
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type IUserProfileInput = {
  displayName?: InputMaybe<Scalars['String']>;
  photoURL?: InputMaybe<Scalars['String']>;
};

export type IUserRoleInputInput = {
  description?: InputMaybe<Scalars['String']>;
  inheritedBySystemRole?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type IUserRoleResponse = {
  __typename: 'UserRoleResponse';
  description?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  inheritedBySystemRole?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type IValidateApiKeyRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type IValidateApiKeyResponse = {
  __typename: 'ValidateAPIKeyResponse';
  scope?: Maybe<IApiKeyScope>;
  success?: Maybe<Scalars['Boolean']>;
};

export type IValidateDocumentError = {
  __typename: 'ValidateDocumentError';
  documentId?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type IValidateDocumentFieldRequestInput = {
  blueprintBranch?: InputMaybe<Scalars['String']>;
  blueprintFieldId?: InputMaybe<Scalars['String']>;
  documentFieldLocaleId?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IValidateDocumentFieldResponse = {
  __typename: 'ValidateDocumentFieldResponse';
  validation?: Maybe<IValidation>;
};

export type IValidateDocumentRequestInput = {
  blueprintBranch?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type IValidateDocumentResponse = {
  __typename: 'ValidateDocumentResponse';
  validation?: Maybe<IValidation>;
};

export type IValidateManyDocumentsRequestInput = {
  validateDocumentRequests?: InputMaybe<Array<InputMaybe<IValidateDocumentRequestInput>>>;
};

export type IValidateManyDocumentsResponse = {
  __typename: 'ValidateManyDocumentsResponse';
  batchErrors?: Maybe<Array<Maybe<IValidateDocumentError>>>;
  documentsValidations?: Maybe<Array<Maybe<IDocumentValidation>>>;
};

export type IValidateProjectDiscountCodeRequestInput = {
  billingPlanName: Scalars['String'];
  discountCode: Scalars['String'];
  projectId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type IValidateProjectDiscountCodeResponse = {
  __typename: 'ValidateProjectDiscountCodeResponse';
  discountPercentage?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type IValidateReleaseRequestInput = {
  projectId?: InputMaybe<Scalars['String']>;
  releaseId?: InputMaybe<Scalars['String']>;
};

export type IValidateReleaseResponse = {
  __typename: 'ValidateReleaseResponse';
  releaseId?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['Boolean']>;
  validationErrors?: Maybe<Array<Maybe<IReleaseValidationDocumentErrors>>>;
};

export type IValidation = {
  __typename: 'Validation';
  errors?: Maybe<Array<Maybe<IValidationError>>>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type IValidationError = {
  __typename: 'ValidationError';
  blueprintFieldId?: Maybe<Scalars['String']>;
  documentFieldLocaleId?: Maybe<Scalars['String']>;
  errorCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  errorDetails?: Maybe<Scalars['Map']>;
};

export type IVerifyActionCodeRequestInput = {
  action: Scalars['String'];
  code: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IVerifyActionCodeResponse = {
  __typename: 'VerifyActionCodeResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type IViewCreateInputInput = {
  iconUrl?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['Any']>;
  target?: InputMaybe<IViewTarget>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IViewResponse = {
  __typename: 'ViewResponse';
  iconUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  pinned?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['Int']>;
  privat?: Maybe<Scalars['Boolean']>;
  query?: Maybe<Scalars['Any']>;
  target?: Maybe<IViewTarget>;
  title?: Maybe<Scalars['String']>;
};

export enum IViewTarget {
  Assets = 'ASSETS',
  Components = 'COMPONENTS',
  Documents = 'DOCUMENTS'
}

export type IViewUpdateInputInput = {
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['Any']>;
  target?: InputMaybe<IViewTarget>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IViewUpsertInputInput = {
  iconUrl?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['Any']>;
  target?: InputMaybe<IViewTarget>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  viewId?: InputMaybe<Scalars['String']>;
};

export type IWebhookCallConnection = {
  __typename: 'WebhookCallConnection';
  edges?: Maybe<Array<Maybe<IWebhookCallConnectionEdge>>>;
  pageInfo?: Maybe<IPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IWebhookCallConnectionEdge = {
  __typename: 'WebhookCallConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<IWebhookCallPaginationResponse>;
};

export type IWebhookCallPaginationResponse = {
  __typename: 'WebhookCallPaginationResponse';
  createdAt?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
  webhookCallId?: Maybe<Scalars['String']>;
  webhookId?: Maybe<Scalars['String']>;
};

export type IWebhookCallResponse = {
  __typename: 'WebhookCallResponse';
  createdAt?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Any']>;
  requestHeaders?: Maybe<Array<Maybe<IWebhookHeader>>>;
  responseBody?: Maybe<Scalars['Any']>;
  responseHeaders?: Maybe<Array<Maybe<IWebhookHeader>>>;
  responseStatusCode?: Maybe<Scalars['Int']>;
  webhookCallId?: Maybe<Scalars['String']>;
  webhookId?: Maybe<Scalars['String']>;
};

export type IWebhookCallsFilterInput = {
  webhookId?: InputMaybe<Scalars['String']>;
};

export type IWebhookHeader = {
  __typename: 'WebhookHeader';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type IWebhookHeaderInput = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type IWebhookInputInput = {
  description?: InputMaybe<Scalars['String']>;
  headers?: InputMaybe<Array<InputMaybe<IWebhookHeaderInput>>>;
  method?: InputMaybe<IHttpMethod>;
  name?: InputMaybe<Scalars['String']>;
  trigger?: InputMaybe<Array<InputMaybe<IWebhookTriggerEvent>>>;
  url?: InputMaybe<Scalars['String']>;
};

export type IWebhookResponse = {
  __typename: 'WebhookResponse';
  createdAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  headers?: Maybe<Array<Maybe<IWebhookHeader>>>;
  method?: Maybe<IHttpMethod>;
  name?: Maybe<Scalars['String']>;
  sucessfulCallsLast30Days?: Maybe<Scalars['Int']>;
  trigger?: Maybe<Array<Maybe<IWebhookTriggerEvent>>>;
  unsucessfulCallsLast30Days?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  webhookId?: Maybe<Scalars['String']>;
};

export enum IWebhookTriggerEvent {
  BlueprintCreate = 'BLUEPRINT_CREATE',
  BlueprintDelete = 'BLUEPRINT_DELETE',
  BlueprintUpdate = 'BLUEPRINT_UPDATE',
  DocumentCreate = 'DOCUMENT_CREATE',
  DocumentDelete = 'DOCUMENT_DELETE',
  DocumentFieldUpdate = 'DOCUMENT_FIELD_UPDATE',
  DocumentUpdate = 'DOCUMENT_UPDATE',
  ProjectDelete = 'PROJECT_DELETE',
  ProjectMemberAssinged = 'PROJECT_MEMBER_ASSINGED',
  ProjectMemberDeassinged = 'PROJECT_MEMBER_DEASSINGED',
  ProjectUpdate = 'PROJECT_UPDATE'
}

export type IWebhookUpsertInputInput = {
  description?: InputMaybe<Scalars['String']>;
  headers?: InputMaybe<Array<InputMaybe<IWebhookHeaderInput>>>;
  method?: InputMaybe<IHttpMethod>;
  name?: InputMaybe<Scalars['String']>;
  trigger?: InputMaybe<Array<InputMaybe<IWebhookTriggerEvent>>>;
  url?: InputMaybe<Scalars['String']>;
  webhookId?: InputMaybe<Scalars['String']>;
};

export enum IWithWithoutEnum {
  With = 'WITH',
  Without = 'WITHOUT'
}

export type I_Service = {
  __typename: '_Service';
  sdl?: Maybe<Scalars['String']>;
};

export type IGoogle_Protobuf_FieldMaskInput = {
  paths?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};
