import {
  PEER_MESSAGE_TYPE_ACTIVE_DOCUMENT_CHANGE,
  PEER_MESSAGE_TYPE_ACTIVE_URL,
  PEER_MESSAGE_TYPE_FIELD_ACTIVE_ON,
  PEER_MESSAGE_TYPE_FIELD_DISSMISS,
  PEER_MESSAGE_TYPE_FIELD_INACTIVE_ON,
  PEER_MESSAGE_TYPE_FIELD_SUBSCRIBE_ON,
  PEER_MESSAGE_TYPE_FIELD_UNSUBSCRIBE_ON,
  PEER_MESSAGE_TYPE_HOT_FIELD_UPDATE,
  PEER_MESSAGE_TYPE_PEER_MUTATION,
  PEER_MESSAGE_TYPE_PREVIEW_FIELD_ACTIVE,
  PEER_MESSAGE_TYPE_PREVIEW_FIELD_UPDATE,
  PEER_MESSAGE_TYPE_Y_AWARENESS,
  PEER_MESSAGE_TYPE_Y_CONQUER,
  PEER_MESSAGE_TYPE_Y_DISSMISS,
  PEER_MESSAGE_TYPE_Y_INIT,
  PEER_MESSAGE_TYPE_Y_PROVIDE,
  PEER_MESSAGE_TYPE_Y_UPDATE,
} from "./constants";
import { ICollaborationState, IPeer } from "./types";
import { handleFieldSubscribeOn } from "./peer-message-handler/handleFieldSubscribeOn";
import { handleFieldActiveOn } from "./peer-message-handler/handleFieldActiveOn";
import { handleFieldInactiveOn } from "./peer-message-handler/handleFieldInactiveOn";
import { handleHotFieldUpdate } from "./peer-message-handler/handleHotFieldUpdate";
import { handleFieldUnsubscribeOn } from "./peer-message-handler/handleFieldUnsubscribeOn";
import { handleActiveDocumentChange } from "./peer-message-handler/handleActiveDocumentChange";
import { handleActiveURL } from "./peer-message-handler/handleActiveURL";
import { handlePeerMutation } from "./peer-message-handler/handlePeerMutation";
import { handleFieldDissmiss } from "./peer-message-handler/handleFieldDissmiss";
import { handlePreviewFieldActive } from "./peer-message-handler/handlePreviewFieldActive";
import { handlePeerYDocMessage } from "./ydoc/handlePeerYDocMessage";

export const PEER_MESSAGE_TYPE_MAP = {
  [PEER_MESSAGE_TYPE_FIELD_SUBSCRIBE_ON]: "Field Subscribe On",
  [PEER_MESSAGE_TYPE_FIELD_UNSUBSCRIBE_ON]: "Field Unsubscribe On",
  [PEER_MESSAGE_TYPE_FIELD_DISSMISS]: "Field Dismiss",
  [PEER_MESSAGE_TYPE_FIELD_ACTIVE_ON]: "Field Active On",
  [PEER_MESSAGE_TYPE_FIELD_INACTIVE_ON]: "Field Inactive On",
  [PEER_MESSAGE_TYPE_ACTIVE_DOCUMENT_CHANGE]: "Active Document Change",
  [PEER_MESSAGE_TYPE_ACTIVE_URL]: "Active URL",
  [PEER_MESSAGE_TYPE_PEER_MUTATION]: "Peer Mutation",
  [PEER_MESSAGE_TYPE_HOT_FIELD_UPDATE]: "hot field update",
  [PEER_MESSAGE_TYPE_PREVIEW_FIELD_ACTIVE]: "preview field active",
  [PEER_MESSAGE_TYPE_PREVIEW_FIELD_UPDATE]: "preview field update",
  [PEER_MESSAGE_TYPE_Y_CONQUER]: "Y_CONQUER",
  [PEER_MESSAGE_TYPE_Y_DISSMISS]: "Y_DISSMISS",
  [PEER_MESSAGE_TYPE_Y_INIT]: "Y_INIT",
  [PEER_MESSAGE_TYPE_Y_PROVIDE]: "Y_PROVIDE",
  [PEER_MESSAGE_TYPE_Y_UPDATE]: "Y_UPDATE",
  [PEER_MESSAGE_TYPE_Y_AWARENESS]: "Y_AWARENESS",
};

export const onPeerMessage = (state: ICollaborationState, peer: IPeer, data: Uint8Array, isAlreadyDelayed = false) => {
  // export const onPeerMessage = (state: ICollaborationState, peer: IPeer, data: Uint8Array) => {
  if (!state.peers.find((localPeer) => peer.clientId === localPeer.clientId)) return;

  const peerMessageType = data[0];

  if (!isAlreadyDelayed && window.c.collaboration_msg_delay) {
    setTimeout(() => {
      onPeerMessage(state, peer, data, true);
    }, window.c.collaboration_msg_delay);
    return;
  }

  // debug only

  if (window.c.debug) {
    // @ts-ignore
    window.c.collaboration.callstack_incoming = window.c.collaboration.callstack_incoming || [];
    PEER_MESSAGE_TYPE_MAP[peerMessageType] &&
      // @ts-ignore
      window.c.collaboration.callstack_incoming.push(`${PEER_MESSAGE_TYPE_MAP[peerMessageType]} (${peer.clientId})`);
  }

  switch (peerMessageType) {
    case PEER_MESSAGE_TYPE_FIELD_SUBSCRIBE_ON:
      handleFieldSubscribeOn(state, peer, data);
      break;
    case PEER_MESSAGE_TYPE_FIELD_UNSUBSCRIBE_ON:
      handleFieldUnsubscribeOn(state, peer, data);
      break;
    case PEER_MESSAGE_TYPE_FIELD_DISSMISS:
      handleFieldDissmiss(state, peer, data);
      break;
      break;
    case PEER_MESSAGE_TYPE_FIELD_ACTIVE_ON:
      handleFieldActiveOn(state, peer, data);
      break;
    case PEER_MESSAGE_TYPE_FIELD_INACTIVE_ON:
      handleFieldInactiveOn(state, peer, data);
      break;
    case PEER_MESSAGE_TYPE_ACTIVE_DOCUMENT_CHANGE:
      handleActiveDocumentChange(state, peer, data);
      break;
    case PEER_MESSAGE_TYPE_ACTIVE_URL:
      handleActiveURL(state, peer, data);
      break;
    case PEER_MESSAGE_TYPE_PEER_MUTATION:
      handlePeerMutation(data);
      break;
    case PEER_MESSAGE_TYPE_HOT_FIELD_UPDATE:
      handleHotFieldUpdate(state, data);
      break;
    case PEER_MESSAGE_TYPE_PREVIEW_FIELD_ACTIVE:
      handlePreviewFieldActive(state, peer, data);
      break;
    case PEER_MESSAGE_TYPE_Y_INIT:
    case PEER_MESSAGE_TYPE_Y_PROVIDE:
    case PEER_MESSAGE_TYPE_Y_DISSMISS:
    case PEER_MESSAGE_TYPE_Y_CONQUER:
    case PEER_MESSAGE_TYPE_Y_UPDATE:
    case PEER_MESSAGE_TYPE_Y_AWARENESS:
      handlePeerYDocMessage(state, peer, data, peerMessageType);
      break;
    default:
      console.log("Unknown peer message type:", peerMessageType);
      break;
  }
};
