import gql from "graphql-tag";

export const fragment_blueprintGroup = gql`
  fragment fragment_blueprintGroup on BlueprintGroup {
    blueprintGroupId
    name
    fields {
      blueprintGroupId
      blueprintId
      description
      blueprintFieldId
      name
      system
      options {
        disableInApi
        disableInUi
        localized
        external
        primary
        required
        uniqueGlobal
        uniqueLocal
        code {
          languages
          min
          max
        }
        connection {
          connectedIds
          max
          min
          multiple
          visualization
          variant
        }
        datetime {
          time
          max
          min
        }
        extension {
          height
          pattern
          url
        }
        file {
          pattern
          focalPoint
        }
        float {
          max
          min
        }
        int {
          max
          min
        }
        richtext {
          features {
            alignJustify
            alignCenter
            alignLeft
            alignRight
            blockquote
            bold
            code
            codeblock
            frame
            h1
            h2
            h3
            h4
            h5
            h6
            link
            italic
            ol
            ul
            table
          }
          assetLinking
          assetBlueprintIds
          documentLinking
          documentBlueprintIds
          componentLinking
          componentBlueprintIds
          max
          min
          pattern
        }
        select {
          items {
            key
            value
          }
          max
          min
          multiple
          outputEnumType
        }
        string {
          max
          min
          multiline
          pattern
        }
        tag {
          max
          min
          pattern
        }
        video {
          pattern
        }
      }
      title
      type
    }
  }
`;
